<template>
  <Menu />
</template>

<script>
import Menu from "@/components/menu/index.vue";

export default {
  components: {
    Menu
  }
};
</script>
