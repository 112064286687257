<template>
  <div>
    <Tools />
  </div>
</template>

<script>
import Tools from '@/components/tools/index.vue';

export default {
  components: { Tools }
};
</script>
