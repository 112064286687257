<template>
  <div>
    <CaseStorage />
  </div>
</template>

<script>
import CaseStorage from "@/components/setting/case-storage.vue";

export default {
  components: {
    CaseStorage
  }
};
</script>