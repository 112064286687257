<template>
  <div>
    <Moritarot />
  </div>
</template>

<script>
import Moritarot from "@/components/reports/moritarot.vue";

export default {
  components: {
    Moritarot
  }
};
</script>
