import { ref, computed } from "@vue/composition-api";

function paddedFormat(num) {
  return num < 10 ? "0" + num : num; 
}
// timestamp to min and seconds
const oneSecond = 1
const oneMin = 60 * oneSecond
export function useTimeTracker (secondsTime = 0) {
  const sec = ref(secondsTime)
  const minutes = computed(() => paddedFormat(Math.floor(sec.value / oneMin)));
  const seconds = computed(() => paddedFormat(((sec.value % oneMin) / oneSecond).toFixed(0)));

  const isoTime = computed(() => `${minutes.value}:${seconds.value}`)

  return {
    minutes,
    seconds,
    isoTime
  }
}

