<template>
  <VCard>
    <VCardHeader>
      <h1>還原紀錄</h1>
    </VCardHeader>
    <VCardContent>
      <div v-for="(item, index) in questionList" :key="index" class="item">
        <Record :record="item"></Record>
      </div>
    </VCardContent>
    <VCardFooter>
      <button @click="$emit('close')" class="btn-default">關閉</button>
    </VCardFooter>
  </VCard>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import VCard, {
  VCardFooter,
  VCardHeader,
  VCardContent,
} from "@/components/common/VCard";
import Record from "../shared/record";
import { getters } from "../store";

export default defineComponent({
  components: {
    VCard,
    VCardFooter,
    VCardHeader,
    VCardContent,
    Record,
  },
  setup() {
    const questionList = getters.customAnswer

    return {
      questionList,
    };
  },
});
</script>

<style lang="scss" scoped>
.v-card {
  text-align: center;
  border-radius: 0;

  &__header {
    padding: 25px 0;
    justify-content: center;
    h1 {
      font-size: 24px;
      font-weight: 600;
    }
  }

  &__content {
    padding: 0 31px;
  }

  &__footer {
    justify-content: center;
    padding: 0;
    .btn-default {
      background-color: #b1b1b1;
      width: 100%;
      padding: 24px;
      font-size: 24px;
      border: none;
    }
  }
}

.item {
  margin-bottom: 30px;
}
</style>
