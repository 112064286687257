<template>
  <div>
    <Reports />
  </div>
</template>

<script>
import Reports from "@/components/reports/index.vue";

export default {
  components: {
    Reports
  }
};
</script>