<template>
  <div>
    <Game />
  </div>
</template>

<script>
import Game from "@/components/game/index.vue";

export default {
  components: {
    Game
  }
};
</script>
