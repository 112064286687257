<template>
  <div>
    <Stories />
  </div>
</template>

<script>
import Stories from "@/components/stories/index.vue";

export default {
  components: {
    Stories
  }
};
</script>
