import { reactive, readonly } from "@/composition";


export const state = reactive({
  isLoading: true,
  topicRecord: {},
  profile: {}
})

export const getters = {
  getTopicInfo (topic) {
    return state.topicRecord[topic]
  }
}

export const mutations = {
  openLoading () {
    state.isLoading = true
  },
  closeLoading () {
    state.isLoading = false
  },
  setTopicRecord (payload) {
    state.topicRecord = Object.assign({}, state.topicRecord, payload)
  },
  setProfile (payload) {
    state.profile = Object.assign({}, state.profile, payload)
  }
}

export default readonly({
  state,
  mutations
})
