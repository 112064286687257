import { ref, shallowRef } from "@vue/composition-api";

export function useFetcher(fetcher) {
  const data = shallowRef(null);
  const loading = ref(false);
  const error = shallowRef(null);

  const getData = async (...params) => {
    loading.value = true;
    data.value = null;
    error.value = null;
    try {
      const resp = await fetcher(...params);
      data.value = resp.data
    } catch (err) {
      error.value = err;
    } finally {
      loading.value = false;
    }
  };

  return {
    data, loading, error, getData,
  };
}