<template>
  <div id="shop">
    
      <div v-if="loading" class="loading-page">
      <div class="loading-spinner">
        <img src="~$images/store/shoploading.gif" alt="Loading..." />
      </div>
    </div>
    <div v-else>
      
    <div class="banner"></div>
    <main>
      <section class="own-tools">
        <aside><img src="~$images/store/coins-1.png"> {{ ownCoins }} 枚</aside>
        <aside><img src="~$images/store/candy.png"> {{ ownCandies }} 顆</aside>
        <aside><img src="~$images/store/cake.png"> {{ ownCakes }} 塊</aside>
        <aside><img src="~$images/store/ticket.png"> {{ ownTicketForLottery }} 張</aside>
      </section>
      <section class="form">
        <form>
          <b-field label="請輸入兌換序號" :type="labelType" :message="message">
            <div class="token-input">
                <b-input v-model="token"></b-input>
                <b-button @click="submitForm">兌換</b-button>
            </div>
          </b-field>
          <div>
          </div>
        </form>
      </section>
      <transition>
        <component
          :key='page'
          :is='page'
          :items='items'
          :lineID='lineID'
          :ownStoryIds='ownStoryIds'
        ></component>
      </transition>
      <!-- <Tools :items='items' :lineID='lineID'/> -->
    </main>
   </div>
  </div>
</template>
<script>
import axios from "axios";
import Tools from './components/tools.vue'
import Stories from './components/stories.vue'

export default {
  components: {
    Tools,
    Stories
  },
  data() {
    return {
      loading: true,
      page: this.$route.params.page,
      ownCoins: 0,
      ownCandies: 0,
      ownCakes: 0,
      ownStoryIds: [],
      ownTicketForLottery: 0,
      lineID: '',
      token: '',
      labelType: '',
      message: '',
      items: [],
      toolItems: [],
      storyItems: [],
      liffIds: {
        tools: '1653951718-o9GNrmEd',
        stories: '1653951718-07N1ya38'
      }
    };
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('reset')) {
      if (urlParams.get('group_id')) {
        localStorage.setItem('groupId', urlParams.get('group_id'));
      } else {
        localStorage.removeItem('groupId');
      }
      window.history.pushState(null, null, window.location.pathname);
    }
    try {
      await window.liff.init({ liffId: this.liffIds[this.page] })
      if (!window.liff.isLoggedIn()) {
        alert("尚未登入");
        return window.liff.login();
      }
      const context = await window.liff.getContext();
      const userId = context.userId;
      this.lineID = userId;
      // this.lineID = 'U016222f6eeee6bc349074ce00e46aa37';
      // const userId = this.lineID;
      this.getProfileData(userId);
      if (this.page === 'tools') {
        if (this.toolItems.length === 0) {
          const response = await axios.get('https://backend.judicial-intern.club/api/v4/products');
          this.toolItems = response.data.products;
          this.items = response.data.products;
        } else {
          this.items = this.toolItems;
        }
      } else if (this.page === 'stories') {
        if (this.storyItems.length === 0) {
          const response = await axios.get('https://backend.judicial-intern.club/api/v4/products?product_itemable_type=story');
          this.storyItems = response.data.products;
          this.items = response.data.products;
          console.log(this.items);
        } else {
          this.items = this.storyItems;
        }
      }
      this.loading = false;
    } catch (e) {
      alert(e);
    }
  },
  methods: {
    getProfileData(lineID){
      axios.get(`https://backend.judicial-intern.club/api/v2/members/${lineID}/tools`)
      .then(response => {
        this.init(response.data)
      }).catch(error => {
        alert(error)
      })
    },
    init(data){
      this.ownCoins = data.member.coins;
      this.ownCandies = data.member.candy;
      this.ownCakes = data.member.cakes;
      this.ownTicketForLottery = data.member.ticket_for_lottery;
      this.ownStoryIds = data.member.ownStoryIds;
    },
    submitForm(){
      axios.post(`https://backend.judicial-intern.club/api/v2/members/${this.lineID}/tokens`, {
        token: this.token
      }).then(response => {
        let success = response.data.success,
            message = response.data.message;
        this.message = message
        if (success) {
          this.labelType = 'is-success';
          this.ownCoins = response.data.coins;
          this.ownCandies = response.data.candy;
          this.ownCakes = response.data.cakes;
          this.ownTicketForLottery = response.data.ticketForLottery;
        } else {
          this.labelType = 'is-danger'
        }
      })
    }
  }
};
</script>
<style lang="scss" scoped src="./style.scss"></style>
