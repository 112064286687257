<template>
  <div>
    <div v-if="analyticDatas">
      <div v-for="(analyticData, key) in analyticDatas" :key="key">
        <p>{{ analyticData.label }}:{{ analyticData.count }}</p>
      </div>
    </div>

    <div v-for="(dataset, key) in lineChartDatasets" :key="key">
      <line-chart v-if="dataset != ''" :line-data="dataset" :options="options"/>
    </div>

    <div v-for="(datasets, key) in mutipleLineChartDatasets" :key="key">
      <div v-for="(dataset, key) in datasets" :key="key">
        <line-chart v-if="dataset != ''" :line-data="dataset" :options="options"/>
      </div>
    </div>

    <div v-for="(dataset, key) in datasets" :key="key">
      <bar-chart v-if="dataset != ''" :bar-data="dataset"/>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/reports/bar-chart.vue';
import LineChart from '@/components/reports/line-chart.vue';
import axios from 'axios';

// const API_URL = 'http://localhost:3000/api/reports';
const API_URL = 'https://moritaro-nwqezholyq-de.a.run.app/api/reports';

export default {
  components: {
    BarChart, LineChart
  },
  mounted(){
    Object.keys(this.datasets).forEach(k => {
      axios.get(`${API_URL}/${k}`)
           .then(response => {
             this.datasets[k] = {
               labels: response.data.labels,
               datasets: [
                { label: response.data.data_name,
                  data: response.data.datasets,
                  pointBackgroundColor: 'white',
                  borderWidth: 1,
                  pointBorderColor: '#249EBF',
                  backgroundColor: '#f87979' }
               ]
             }
           })
           .catch(error => console.log(error));
    })
    Object.keys(this.lineChartDatasets).forEach(k => {
      axios.get(`${API_URL}/${k}`)
           .then(response => {
             this.lineChartDatasets[k] = {
               labels: response.data.labels,
               datasets: response.data.datasets
             }
           })
           .catch(error => console.log(error));
    })
    Object.keys(this.mutipleLineChartDatasets).forEach(k => {
      axios.get(`${API_URL}/${k}`)
           .then(response => {
             this.mutipleLineChartDatasets[k] = response.data.multiple_datasets
           })
           .catch(error => console.log(error));
    })
     axios.get(`${API_URL}/analytic_datas`)
           .then(response => {
             this.analyticDatas = response.data.analytic_datas;
           })
           .catch(error => console.log(error));
  },
  data () {
    return {
      datasets: { new_user: '', divination_dau: '', average_divination: '', retention: '' },
      options: { responsive: true, maintainAspectRatio: false },
      lineChartDatasets: { divination_type_dau: '', user_dau: '' },
      mutipleLineChartDatasets: { difference_user_divination: '' },
      analyticDatas: undefined
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
