<template>
  <div class="level">
    <p>難度：<p>
    <div class="stars" v-for="index in 5" :key="index">
      <img :src="level >= index ? stars.normal : stars.gray" />
    </div>
  </div>
</template>

<script>
  export default {
    props: ['level'],
    data() {
      return {
        stars: {
          normal: require("@/assets/images/star.png"),
          gray: require("@/assets/images/gray_star.png"),
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .level {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .stars {
      img {
        width: 20px;
        margin-right: 4px;
      }
    }
  }
</style>