<template>
  <div id="chinese-new-year">
    <div class="banner">
      <img src="~$images/activity/divination/picnic-banner.jpeg" />
    </div>
    <header class="tools">
      <ul>
        <li><img src="~$images/store/coins-1.png">{{ownTools.point}} 枚</li>
        <li><img src="~$images/store/ticket.png">{{ownTools.tool}} 張</li>
      </ul>
      <nav>
        <a href="https://liff.line.me/1653951718-GJYJkrDE">
          查看占卜結果
          <font-awesome-icon :icon="['fas', 'arrow-right']" />
        </a>
      </nav>
    </header>
    <main class="content">
      <section v-if="!waitForScratching">
        <h1>冥鏡的邀請</h1>
        <p>嗨，判官，要占卜一下嗎？</p>
        <img src="~$images/activity/divination/start-scratch.jpeg" />
        <nav>
          <a @click="onClickStartScratchedBtn('point')">鎮魂璧占卜</a>
          <a @click="onClickStartScratchedBtn('tool')">敕令占卜</a>
        </nav>
        <aside class="summary">
          <h6>占卜機率</h6>
          <p>
            <span v-for='(reward, index) in rewards' :key="`reward-${index}`">
              <strong>{{reward.percetage}}%</strong><b>{{reward.name}}*{{reward.quantity}}</b>({{charmColor(reward.percetage)}})
              <br>
            </span>
          </p>
        </aside>
      </section>
      <div class="scratch-component" v-if="waitForScratching">
        <div v-if="!finishScratched" class="scratched-title">
          <h4>心誠則靈</h4>
          <p>請在符咒下方描繪你的敕令</p>
        </div>
        <div v-else class="scratched-title">
          <h4>占卜結果</h4>
          <p>其中包含了籤詩以及解籤的內容，祝好運！</p>
        </div>
        <scratch-card :key="renderCount"
                      :cardWidth=210
                      :cardHeight=373
                      :finishPercent=90
                      :brushUrl="brushUrl"
                      :imageUrl="hiddenImageUrl"
                      @complete="reachFinishPercent"
                      :forceReveal="forceReveal">
        <div v-bind:class="[giftColor(rewardPercentage), 'scratch-card']"></div>
        </scratch-card>
        <p class="gift-content" v-if="rewardResult.fortuneStick">{{rewardResult.fortuneStick}}</p>
        <p class="gift-content" v-if="rewardResult.message">{{rewardResult.message}}</p>
        <p class="gift-content" v-if="rewardResult.token">
          序號為：<br>{{rewardResult.token}}
          <button @click="copyToken">點我複製序號</button>
          <button @click="shareToken">點我分享</button>
        </p>
        <p v-if="finishScratched">
          要再使用「<span class="coin-number">{{toolNameTranslation[currentUseTool]}}</span>」占卜一次嗎？
        </p>
        <div class="control-btn">
          <button @click="onClickStartScratchedBtn(currentUseTool)" v-if="finishScratched">再來一張</button>
          <button @click="forceReveal = true" v-else>完成了！</button>
        </div>
      </div>
    </main>
    <b-modal v-model="warn.isShown">
      <div class="warning-text">
        <h4>Opps 😣</h4>
        <p>{{warn.content}} 🥺</p>
        <nav>
          <a class="cancel" @click="warn.isShown = false">
            {{warn.cancelText}}
          </a>
          <a href="https://liff.line.me/1653951718-o9GNrmEd" >
            {{warn.acceptText}}
          </a>
        </nav>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ScratchCard from 'vue-scratchcard';
import { baseRequset } from "@/api/base";
import { copyContent } from '@/components/share/copy.js';
import { lineContext } from '@/api/line.js';

export default {
  components: { ScratchCard },
  data() {
    return {
      renderCount: 0,
      forceReveal: false,
      brushUrl: require(`@/assets/images/activity/divination/pen.png`),
      percentage: 0,
      finishScratched: false,
      warn: { isShown: false, content: '你的鎮魂璧數量好像不夠了',
              cancelText: '我瞭解了', acceptText: '立即購買' },
      toolNameTranslation: { point: '鎮魂璧', tool: '敕令' },
      toolUnit: { point: '枚', tool: '張' },
      ownTools: { point: 0, tool: 0 },
      currentUseTool: 'tool',
      lineId: '',
      hiddenImageUrl: require('@/assets/images/activity/divination/cover.png'),
      rewardResult: {},
      price: 10000,
      rewards: [],
      waitForScratching: false,
      rewardPercentage: 0
    };
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('reset')) {
      if (urlParams.get('group_id')) {
        localStorage.setItem('groupId', urlParams.get('group_id'));
      } else {
        localStorage.removeItem('groupId');
      }
      window.history.pushState(null, null, window.location.pathname);
    }
    try {
      const context = await lineContext('1653951718-MeyWzYX3');
      const userId = context.userId;
      // const userId = 'U016222f6eeee6bc349074ce00e46aa37';
      this.getProfileData(userId);
      this.getToolItemData(userId);
    } catch (e) {
      alert(e);
    }
  },
  methods: {
    init(data) {
      this.ownTools = { point: data.coinNumber, tool: data.ticketForLottery };
    },
    getProfileData(lineId){
      this.lineId = lineId;
      baseRequset.get('/api/v1/line/coin_number', {
        params: { line_id: lineId }
      }).then(response => { 
        this.init(response.data)
      }).catch(error => {
        alert(error)
      })
    },
    getToolItemData(lineId){
      baseRequset.get('/api/v4/tool_items/ticket_for_lottery', {
        params: { line_id: lineId }
      }).then(response => {
        this.setToolItemData(response.data);
      }).catch(error => {
        alert(error);
      });
    },
    setToolItemData(data){
      this.price = data.price;
      this.rewards = data.rewards;
      this.waitForScratching = data.waitForScratching;
    },
    readyStarted(data){
      if (data.success) {
        this.waitForScratching = true;
        this.renderCount++;
        this.forceReveal = false;
        this.rewardPercentage = 0;
        this.rewardResult = {};
        this.finishScratched = false;
        this.ownTools = { point: data.point, tool: data.toolNumber };
      } else {
        this.showWarning(this.currentUseTool);
      }
    },
    reachFinishPercent(){
      baseRequset.post('/api/v4/priorities', {
        line_id: this.lineId, code: 'ticket_for_lottery'
      }).then(response => {
        this.rewardResult = { message: response.data.message, token: response.data.token,
                              fortuneStick: response.data.fortuneStick }
        this.rewardPercentage = response.data.percentage;
        this.finishScratched = true;
        this.ownTools = { point: response.data.pointNumber, tool: response.data.toolNumber };
      }).catch(error => {
        alert(error);
      });
    },
    onClickStartScratchedBtn(tool = undefined){
      this.currentUseTool = tool;
      if ((this.currentUseTool === 'point' && this.ownTools.point < parseInt(this.price)) ||
          (this.currentUseTool === 'tool' && this.ownTools.tool < 1)) {
        this.showWarning(this.currentUseTool);
        return;
      }
      baseRequset.put('/api/v4/tool_items/ticket_for_lottery', {
        line_id : this.lineId, use_type: this.currentUseTool
      }).then(response => {
        this.readyStarted(response.data);
      }).catch(error => {
        alert(error);
      });
    },
    showWarning(toolType) {
      const toolName = toolType === 'point' ? '鎮魂璧' : '敕令';
      this.warn = { isShown: true, content: `你的 ${toolName} 數量好像不夠了`,
                    cancelText: '我瞭解了', acceptText: '立即購買' };
    },
    charmColor(percentage) {
      if (percentage < 10) {
        return '彩色火焰符咒';
      } else if (percentage < 30) {
        return '金色火焰符咒';
      } else {
        return '銀色火焰符咒';
      }
    },
    giftColor(percentage) {
      console.log(percentage);
      if (percentage === 0) {
        return 'empty';
      } else if (percentage < 10) {
        return 'mult-color';
      } else if (percentage < 30) {
        return 'red';
      } else {
        return 'blue';
      }
    },
    copyToken() {
      copyContent(this.rewardResult.token, '序號複製成功！快去使用吧～');
    },
    shareToken() {
      window.liff.shareTargetPicker([
        {
          type: 'text',
          text: `恭喜得到<${this.rewardResult.message}>序號 * 1\n序號為：${this.rewardResult.token}`
        }
      ])
    }
  }
};
</script>

<style scoped lang="scss">
#chinese-new-year {
  background: #F0E7D7;
  min-height: 100vh;
  padding-bottom: 48px;

  .banner {
    position: relative;

    img {
      width: 100%;
    }
  }

  .tools{
    width: 80%;
    max-width: 310px;
    margin: 4px auto 16px;
    display: flex;
    justify-content: space-between;

    ul {
      display: flex;
      align-items: center;

      li {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-right: 8px;

        &:last-child {
          margin-left: 0;
        }

        img {
          width: 20px;
          margin-right: 4px;
        }
      }
    }

    nav {
      a {
        color: #444;
        padding: 4px 8px;
        font-size: 14px;
      }
    }
  }

  .warning-text {
    width: 80%;
    height: 150px;
    max-width: 360px;
    background: #F0E7D7;
    margin: 0 auto;
    border-radius: 8px;
    text-align: center;

    h4 {
      padding-top: 24px;
      margin-bottom: 4px;
      color: #C8342A;
      font-size: 16px;
    }

    p {
      margin-bottom: 16px;
    }

    nav {
      display: flex;
      justify-content: space-around;
      max-width: 240px;
      margin: 0 auto;

      a {
        background: #fff;
        color: #FDBD58;
        padding: 8px 16px;
        border-radius: 8px;
      }
    }
  }

  main.content {
    min-height: calc(100vh - 238px);
    margin: 0px auto;
    width: 210px;

    h1 {
      text-align: center;
      font-size: 20px;
      margin-top: 4px;
    }

    p {
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 700;
    }

    aside {
      width: 210px;
      margin: 5px auto;

      p {
        font-size: 12px;
        font-weight: 400;
      }

      .coin-number {
        color: #C8342A;
      }

      &.summary {
        margin-top: 24px;

        h6 {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 8px;
        }

        p {
          font-size: 12px;
          font-weight: 10px;

          span {
            strong, b {
              display: inline-block;
            }

            strong {
              width: 40px;
            }

            b {
              width: 80px;
            }
          }
        }
      }
    }

    nav {
      display: flex;
      justify-content: space-around;
      margin-top: 16px;
      margin-right: 8px;

      &:last-child {
        margin-right: 0px;
      }
      
      a {
        background: #fff;
        color: #FDBD58;
        padding: 4px 8px;
        border-radius: 8px;
        font-size: 16px;
      }
    }

    .scratch-card {
      width: 210px;
      height: 373px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      align-items: center;
      margin: 0 auto;

      &.empty {
        background-color: #C8342A;
      }

      &.red {
        background-image: url("~$images/activity/divination/red.jpeg");
      }

      &.blue {
        background-image: url("~$images/activity/divination/blue.jpeg");
      }

      &.mult-color {
        background-image: url("~$images/activity/divination/mult-color.jpeg");
      }
    }

    .scratched-title {
      &:first-child {
        margin-bottom: 16px;
      }

      h4 {
        text-align: center;
      }
    }

    .gift-content{
      margin-top: 8px;

      button {
        background: #fff;
        color: #FDBD58;
        border-radius: 8px;
        font-size: 16px;
        margin-top: 4px;
        width: 100%;
        padding-top: 4px;
        padding-bottom: 4px;
        text-align: center;
      }
    }

    .control-btn {
      display: flex;
      justify-content: space-around;
      text-align: center;
      margin-top: 16px;

      button {
        display: block;
        padding: 8px 32px;
        font-size: 14px;
        background: #fff;
        color: #FDBD58;
        border-radius: 8px;
      }
    }
  }
}
</style>
