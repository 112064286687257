<template>
  <div class="Game">
    <div class="header">
      <Nav :judge="judge" :navList="listData" @change-judge="changeJudge" />
    </div>
    <div class="content">
      <transition name="fade">
        <component
          v-if="initDone"
          :key="judge"
          :is="judge"
          :groupId="groupId"
          :memberLineId="lineId"
          :memberName="name"
          :updateFinishedDemo="updateFinishedDemo"
          @change-judge="changeJudge"
          :updateGroupStoryShip="updateGroupStoryShip(groupId, lineId)"
          class="Game__layout"
        ></component>
      </transition>
    </div>
    <b-modal v-model="showFinishedDemo">
      <div class="reminder">
        <img :src="`${require(`@/assets/images/${judge.replace(/s$/, '')}-icon.png`)}`" /> <br/>
        <h4>
          Oops！試玩結束囉<br/>
          還想玩？請購買付費劇本
        </h4>
        <p>
          每個劇本都是實習判官編劇從發想證人、設計證物和整理案發地點，經過判官們驗證，最後整理成可遊玩的劇本，請多多支持。
        </p>
        <div>
          <a class="cancel"
             @click="showFinishedDemo = false">
            再想想
          </a>
          <a class="accept"
             :href="`line://app/1653951718-gKz3KWMB?itemable_type=story&itemable_id=${storyId}&group_id=${groupId}&member_line_id=${lineId}`">
            購買劇本
          </a>
        </div>
      </div>
    </b-modal>
    <Notification />
    <!-- <Loading :isLoading="isLoading"></Loading> -->
  </div>
</template>
<script>
import Vue from "vue";
import Nav from "@/components/game/nav.vue";
// import Loading from "@/components/game/shared/loading";
import APIClient from "@/api";
import { state, mutations } from "./store";
import { getToken, getNavIconList } from "./shared/utils";
import { show, update } from '@/components/game/shared/groupStoryShip';
import Relateds from "@/components/game/pages/relateds";
import Exhibits from "@/components/game/pages/exhibits";
import Souls from "@/components/game/pages/souls";
import Times from "@/components/game/pages/times";
import Hints from "@/components/game/pages/hints";
import Yama from "@/components/game/pages/yama";
import Notification from './shared/notification.vue';
import ActionCableVue from "actioncable-vue";
import eventBus from "@/components/game/store/eventBus";

Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  connectionUrl: "wss://backend.judicial-intern.club/cable", // or function which returns a string with your JWT appended to your server URL as a query parameter
  connectImmediately: true,
});

export default {
  components: {
    Nav,
    Relateds,
    Exhibits,
    Souls,
    Times,
    Hints,
    Yama,
    Notification
  },
  data() {
    return {
      judge: this.$route.params.judge,
      code: '',
      lineId: '',
      name: '',
      groupId: '',
      storyId: '',
      initDone: false,
      hasConnection: false,
      finishedDemo: false, // FIXME: Can be component state.
      showFinishedDemo: false,
      itemTypeTranlateToHadFound: {
        exhibit: 'hadFoundExhibitIds',
        related: 'hadFoundRelatedIds',
        timeline: 'hadFoundTimelineIds',
        position: 'hadFoundPositionIds'
      }
    };
  },
  computed: {
    listData() {
      const list = getNavIconList();
      if (![4991, 1131, 156038].includes(+this.groupId)) {
        return list.filter((item) => item.name !== "yama");
      }

      return list;
    },
    isLoading() {
      return state.isLoading;
    },
  },
  channels: {
    GroupsChannel: {
      connected() {
        console.log('connected');
      },
      received(data) {
        data = JSON.parse(data);
        eventBus.$emit('showNotification', data);
        eventBus.$emit("received", this.itemTypeTranlateToHadFound[data.searchType], data.foundItem);
      },
      disconnected() {
        console.log("disconnected");
      },
      rejected() {
        console.log("rejected");
      },
    },
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('reset')) {
      if (urlParams.get('group_id')) {
        localStorage.setItem('groupId', urlParams.get('group_id'));
      } else {
        localStorage.removeItem('groupId');
      }
      window.history.pushState(null, null, window.location.pathname);
    }
    try {
      const liffId = await getToken(this.judge);
      await window.liff.init({ liffId })
                  .catch((err) => {
                    alert(err)
                    console.log("初始化錯誤", err.message);
                    if (this.groupId === 'U016222f6eeee6bc349074ce00e46aa37') { alert(err.message); }
                    throw err;
                  });

      if (!window.liff.isLoggedIn()) {
        alert("尚未登入");
        return window.liff.login();
      }
      const context = await window.liff.getContext();
      const userId = context.userId;
      if (!userId) { alert('用戶資料不存在'); }
      // const userId = 'U016222f6eeee6bc349074ce00e46aa37';
      // this.groupId = 156038;
      this.lineId = userId;
      this.groupId = localStorage.getItem('groupId') || userId;
      if (!this.groupId) { alert('群組不存在'); }
      await this.getProfile();
      await this.getTopicInfo();
      this.initDone = true;
      this.$cable.subscribe({
        channel: 'GroupsChannel',
        group: this.groupId
      });
      // FIXME: 這邊抓的資料，可以給其他四個頁面用，不用再重複抓一次。
      this.storyId = (await show(this.groupId, this.lineId)).data.ship.story_id;
    } catch (error) {
      console.log(error);
      console.log(`遊戲服務中斷: ${error.message}`);
      window.liff.closeWindow();
    }
  },
  watch: {
    showFinishedDemo: (e) => {
      if (!e) {
        window.close();
        window.liff.closeWindow();
      }
    }
  },
  methods: {
    changeJudge(name) {
      if (this.judge === name) return;

      mutations.openLoading();
      this.judge = name;
    },
    updateGroupStoryShip: update,
    async getProfile() {
      // let memberDataV1 = localStorage.getItem('memberDataV1');
      let profile = {}
      // if (memberDataV1) {
      //   profile = JSON.parse(memberDataV1);
      //   this.setMemberDataV1(profile);
      //   return;
      // }

      profile = (await APIClient.getmemberInfo(this.lineId)).data;
      localStorage.setItem('memberDataV1', JSON.stringify(profile));
      this.setMemberDataV1(profile);
      // FIXME: Remove updating member api. 
      // await APIClient.setProfile({
      //   name: name,
      //   pictureUrl: pictureUrl,
      //   token: token,
      //   statusMessage: statusMessage,
      // });
    },
    async getTopicInfo() {
      const { data } = await APIClient.getTopicInfo(this.lineId);
      const { topic, profile } = data;
      mutations.setTopicRecord(topic);
      mutations.setProfile(profile);
    },
    setMemberDataV1(profile) {
      this.name = profile.displayName;
    },
    updateFinishedDemo() {
      this.finishedDemo = true;
      this.showFinishedDemo = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.Game {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1 0 auto;
    margin-top: 60px;
  }
  .header {
    flex-shrink: 0;
    position: fixed;
    width: 100%;
    z-index: 100001;
  }

  .reminder{
    background-color: #fff;
    padding-top: 50px;
    text-align: center;
    color: #333333;
    img{
      width: 72px;
      padding-bottom: 30px;
    }
    h4{
      font-size: 20px;
      font-weight: 600;
    }
    span{
      font-size: 14px;
    }
    p{
      padding: 35px 50px 35px;
      font-size: 14px;
      text-align: left;
    }
    div {
      height: 80px;
      display: flex;
      a {
        flex: 1;
        align-items: center;
        align-content: center;
        justify-content: center;
        font-size: 24px;
        color: #333333;
        display: flex;

        &.cancel{
          background-color: #b1b1b1;
        }
        &.accept{
          background-color: #b6d765;
        }
      }
    }
  }
}
</style>
