import { provide } from '@vue/composition-api'
import { getters } from '@/components/game/store'

export function useConfig (context) {
  const topicInfo = getters.getTopicInfo('answer')

  // Provide<Banner> 相關圖片及資料
  const $banner = Symbol("$bannerConfig")
  provide($banner, {
    topicTitle: require("@/assets/images/yama/title.png"),
    topicBg: topicInfo.banner,
    topicCharacter: topicInfo.banner_avatar,
    actionBtn: {
      "init": topicInfo.banner_btn,
      "active1": require("@/assets/images/yama/blood-icon-1.png"),
      "active2": require("@/assets/images/yama/blood-icon-2.png"),
      "activeTiming": require("@/assets/images/yama/timing.png")
    }
  })

  // Provide<GameMain> 相關圖片及資料
  const $stage = "$stage"
  provide($stage, {
    init: {
      mainBg: require("@/assets/images/yama/brush-bg.png")
    },
    start: {
      phaseBg: topicInfo.question_block,
      chooseIcon: topicInfo.question_select,
      phaseOptions: topicInfo.question_default,
      phaseOptionActive: require("@/assets/images/yama/active.png"),
      phaseConfirm: topicInfo.question_finished,
      phaseActive: topicInfo.option_active,
      actionBtn: require("@/assets/images/yama/timing-action-btn.png"),
    },
    tool: topicInfo.tool
  })

  // main style
  const mainStyle = {
    "background-image": `url(${topicInfo.background})`,
  };

  const openModal = component => () => {
    context.root.$buefy.modal.open({
      parent: context.parent,
      component,
      canCancel: ['escape','outside'],
      trapFocus: true,
      width: 291
    });
  }

  return {
    mainStyle,
    openModal,
    $banner,
    $stage,
    toolIcon: topicInfo.tool
  }
}