
<script>
  import { Line } from 'vue-chartjs'

  export default {
    extends: Line,
    props: ["line-data", "options"],
    mounted () {
      this.renderChart(this.lineData, this.options)
    }
  }
</script>