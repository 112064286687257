import axios from 'axios';

export const update = (groupId, memberLineId) => (itemType, itemName, options = {}) => {
  axios.put(`https://backend.judicial-intern.club/api/v4/groups/${groupId || memberLineId}/group_story_ships/${memberLineId}`,
            { item_type: itemType, item_name: itemName, ...options })
}

export const show = async (groupId, memberLineId) => {
  return axios.get(`https://backend.judicial-intern.club/api/v4/groups/${groupId || memberLineId}/group_story_ships/${memberLineId}`)
}
