<template>
  <div>
    <Profile />
  </div>
</template>

<script>
import Profile from "@/components/setting/profile-2.vue";

export default {
  components: {
    Profile
  }
};
</script>