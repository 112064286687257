<template>
  <div id="landing-page">
    <header>
      <Nav />
      <div class="banner">
        <div class="content">
          <h1> 實習判官 </h1>
          <p>劇情 LineBot 推理遊戲</p>
          <a href="#join"> 開始推理 </a>
        </div>
      </div>
    </header>

    <main class="judges" id="judges">
      <header>
        <p class="header-bre">
          冥界是所有亡魂都會來的世界，亡魂在這裡經過審判跟清理生前的債務之後，就會重新投胎或前往其他世界。好人有機會進入極樂世界，壞人會下地獄，小善小惡的一般人則會把生前記憶跟遺憾解決之後，就重新投胎。 
        </p>
        <h2>四大特色</h2>
        <p>
          不用下載任何 App，創建 Line 群組即可開始推理 🎮 <br>
          多人協力推理，難解的懸案不再一個人面對 🔍 <br>
          MIT 台灣自製劇本，各種意想不到的情節讓你腦洞大開 🤯 <br>
          獨創冥界世界觀，三位判官的故事等你來發掘 💫 <br>
        </p>
      </header>
      <hr>

      <main>
        <div
          v-for="(judge, index) in allJudges"
          :key="index"
        >
          <header class="judge-image">
            <img v-bind:src="judge.img">
            <p>{{ judge.charactor }}</p>
          </header>
          <main>
            <h3> {{ judge.title }} </h3>
            <p> {{ judge.said }} </p>
            <footer>
              <a> 協助他破案 </a>
              <a>判官介紹</a>
            </footer>
          </main>
        </div>

        <footer>
          <p>
            20世紀人口膨脹，冥界也陷入鬼口膨脹的危機，裝不下那麼多鬼魂的冥界現在只追求效率，把亡魂快速投胎送回陽間。這導致原本有強力審判功能的十殿閻羅，變成了戶政事務所或移民署一般的行政機關。現在的閻王們只負責快速審理亡魂是否適合投胎，不再處理它們的生前事務。
          </p>
        </footer>
        <hr>
      </main>

    </main>

    <main class="steps" id="steps">
      <h2> 三個步驟開始推理 </h2>
      <main>
        <div
          v-for=" ( step, index ) in steps "
          :key="index"
        >
          <h4>{{ step.number }}</h4>
          <h3>{{ step.title }}</h3>
          <img :src="step.img">
          <p>
            {{ step.paragraph }}
          </p>
        </div>
      </main>
      
    </main>

    <aside class="join" id="join">
      <main>
        <h4>現在加入</h4>
        <img src="~$images/qrcode.png">
        <a href="https://line.me/ti/p/@183sknuo" target="_blank">開始推理</a>
      </main>
      <p>
        不少亡魂帶著遺憾來到冥界，結果完全沒有得到任何清理就要投胎回陽間了，它們的遺憾會製造大量負能量。亡魂身上的負能量會造成兩種負面效應：一是讓冥界充滿負能量，結果冥界變熱；二是帶著遺憾跟怨恨的亡魂沒有想通就投胎，會把這些傷痕帶到下一世人生，造成陽間更多問題。充滿負能量的亡魂投胎也要消耗比較多的孟婆湯，害孟婆抱怨連連。
      </p>
      <hr>
    </aside>

    <aside class="faq" id="faqs">
      <h2> FAQ </h2>
      <ul
        v-for="( faq, index ) in faqs"
        :key="index"
      >
        <li>
          {{ faq.question }} <br>
          {{ faq.answer }}
        </li>
      </ul>
    </aside>

    <aside class="sponsor" id="sponsor">
      <main>
        <h4>贊助我們</h4>
        <img src="~$images/donate.png">
        <a>讓我們做得更好</a>
      </main>
    </aside>

    <footer id="fb">
      <img src="~$images/boss.png">
      <aside>
        <p>
          想了解更多實習判官的內容？ <br>
          想第一手收到實習判官的最新資訊？ <br>
          現在加入我們的 FB 社團！ <br>
        </p>
        <a class="fb">
          <img src="~$images/fb-join.png">
        </a>
        <img src="~$images/logo.png">
      </aside>
    </footer>
    <p>
      合作請來信 <br>
      JUDICIALINTERN0419@GMAIL.COM
    </p>
  </div>
</template>

<script>
import Nav from "@/components/nav";

export default {
  components: {
    Nav
  },
  data() {
    return {
      allJudges: this.setJudges(),
      steps: this.setSteps(),
      faqs: this.setQA()
    };
  },
  mounted(){
  },
  methods:{
    setJudges(){
      let judges = [
          {
            name: "mingjing",
            title: "聽風判官 冥鏡",
            img: require("../../../src/assets/images/related-intro.png"),
            id: 1,
            said: "「這種簡單的小情報，花兩分鐘就調查出來了，剩下的就交給你囉。」",
            charactor:"擅於觀察人際互動", 
            path:"https://underworld-judge-intern.webflow.io/judge/mingjing",
            tag:["","76c147234d34-413413d5"]

          },
          {
            name: "meilan",
            title: "察微判官 魅嵐",
            img: require("../../../src/assets/images/exhibit-intro.png"),
            id: 2,
            said: "「我喜歡把證物按照字首筆劃排序，你說我很奇怪？你們這些不重視秩序的人才怪吧。」",
            charactor:"仔細觀察生活中大小事", 
            path:"https://underworld-judge-intern.webflow.io/judge/meilan",
            tag:["cc-about-2", "76c147234d41-413413d5"]
          },
          {
            name: "linyang",
            title: "讀夢判官 靈陽",
            img: require("../../../src/assets/images/soul-intro.png"),
            id: 3,
            said: "「石獅寺有四十四隻石獅子，四十四隻石獅子吃四十四枝芋仔冰芋仔冰好好吃我也好想吃喔端湯上塔？」",
            charactor:"經常對靈魂自言自語",
            path:"https://underworld-judge-intern.webflow.io/judge/linyang",
            tag:["", "98918e198d7e-413413d5"]
          }
            
      ]
      return judges
    },
    setSteps(){
      let steps = [
          {
            number:"步驟一",
            title: "挑選案件",
            paragraph: "首先創建 line 群組，邀請實習判官官方帳號加入之後，邀請其他朋友加入後挑選案件",
            img:require("../../../src/assets/images/select-case.jpg"),
            tag:["heading", "image", "paragraph"]
          },
          {
            number:"步驟二",
            title: "案件推理",
            paragraph: "發揮判官的超能力以獲取線索，注意關係人與證物間可能的連結，一步一步抽絲剝繭揭開案件全貌",
            img:require("../../../src/assets/images/inference-case.jpg"),
            tag:["heading-2", "image-2", "paragraph-2"]
          },
          {
            number:"步驟三",
            title: "回答問題",
            paragraph: "告訴小閻王你們認為的真相，重啟案件還被害者一個公道",
            img:require("../../../src/assets/images/answer-case.jpg"),
            tag:["heading-3", "image-2", "paragraph-2"]
          }

      ]
      return steps
    },
    setQA() {
      let QAs = [
        {
          question: "Q: 實習判官是什麼？",
          answer: "A: 實習判官是一個基於 LINE 應用程式的劇情向 LINEBOT 推理遊戲。"
        },
        {
          question: "Q: 實習判官適合幾個人一起玩？",
          answer: "A: 最佳體驗人數為 3 至 6 人。"
        },
        {
          question: "Q: 邊緣人可以自己一個玩嗎？",
          answer: "A: 可以喔，你只需要創立群組並邀請實習判官加入就可以開始破案。"
        },
        {
          question: "Q: 實習判官要全部人在一個空間才能玩嗎？",
          answer: "A: 如果沒辦法集合所有人也沒關係，建議可以發起 Line 群組電話討論劇情。"
        },
        {
          question: "Q: 實習判官的遊戲目的？",
          answer: "A: 玩家將在遊戲中擔任三位判官的小助手，找到關鍵線索、推理討論最後偵破案件。"
        },
        {
          question: "Q: 實習判官遊戲該如何進行？",
          answer: "A: 請先仔細閱讀小閻王的訊息，觀看阿鬼的跑馬燈，接著向三位判官尋求線索。"
        },
        {
          question: "Q: 推理到一半卡關了怎麼辦？",
          answer: "A: 請去找石獅，那邊有豐富的提示。"
        },
        {
          question: "Q: 任務明明解完了，也顯示案件破解，可是卻無法玩新案件？",
          answer: "A: 請至功能選單 -> 卷宗藏經閣 -> 更換劇本。"
        },
        {
          question: "Q: 實習判官會持續推出新劇本嗎？",
          answer: "A: 我們會不定期推出精彩的劇本，盡請期待！"
        },
        {
          question: "Q: 實習判官適合什麼樣的人玩？",
          answer: "A: 對推理有興趣，喜歡跟朋友一起合作的人，上班上課族空檔想要小小放鬆或是單純想要打發時間的人都可以玩。"
        },
        {
          question: "Q: 我把實習判官加入群組，一段時間了都沒反應是為什麼？",
          answer: "A: 請幫我檢查群組是否已經有其他官方帳號，目前一個 群組只能有一個官方帳號，請先幫我把舊帳號暫時移出群組，並再邀請一次實習判官。"
        },
        {
          question: "Q: 我想要贊助實習判官該怎麼做？",
          answer: "A: 下面有贊助我們的連結，歡迎贊助，讓我們做的更好。"
        }
      ]
      return QAs
    },
  }
}
</script>

<style lang="scss" scoped>
#landing-page{
  > header{
    .banner{
      position: relative;
      width: 100%;
      margin: 50px auto;
      background-image: url("~$images/home-banner.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      padding:0 10px;

      .content{
        max-width: 670px;
        width: 80%;
        margin: 0 auto;
        padding-top: 80px;
        padding-bottom: 130px;

        h1{
          background: rgba(0,0,0,.48);
          font-size: 40px;
          line-height: 80px;
          color: #fff;
          text-align: center;
        }
        p{
          opacity: .6;
          color: #fff;
          text-align: center;
          padding-bottom: 50px;
        }
        a {
          display: block;
          width: 120px;
          text-align: center;
          background: #1a1b1f;
          color: #fff;
          font-size: 12px;
          line-height: 20px;
          border: 1px solid #dbdbdb;
          margin: 0 auto;
          padding: 12px 25px;
          letter-spacing: 2px;
        }
      }
    }
  }

  > main.judges{
    max-width: 960px;
    width: 80%;
    text-align: center;
    margin: 0 auto;
    > header{
      p.header-bre{
        opacity: .6;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 40px;
      }
      h2{
        margin-top: 10px;
        margin-bottom: 15px;
        font-size: 37px;
        line-height: 50px;
        font-weight: 400;
        text-transform: none;
      }
    }

    main{
      > div {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        &:nth-child(2n + 2){
          flex-direction: row;
          @media (max-width: 760px){
            flex-direction: column;
          }
        }
        @media (max-width: 760px){
          flex-direction: column;
        }
        .judge-image{
          text-align:center;
          margin-bottom: 30px;
          img{
            max-width: 450px;
            width: 100%;
          }
          p{
            opacity: .6;
            text-align: center;
            font-size: 12px;
            margin-top: 8px;
          }
        }
        main {
          max-width: 330px;
          h3{
            font-size: 36px;
            line-height: 50px;
            margin-bottom: 12px;
            text-align: left;
          }
          p{
            opacity: .6;
            margin-bottom: 10px;
            text-align: left;
          }
          footer{
            width: 100%;
            display: flex;
            justify-content: space-around;
            a{
              display: inline-block;
              width: 120px;
              background-color: #1a1b1f;
              color: #fff;
              line-height: 20px;
              padding: 12px 25px;
              letter-spacing: 2px;
              font-size: 12px;
            }
          }
        }
      }

      footer{
        p{
          margin-bottom: 40px;
          opacity: 0.6;
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }

  > main.steps{
    max-width: 960px;
    width: 80%;
    text-align: center;
    margin: 0 auto 13px;

    h2{
      margin-top: 60px;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 36px;
      line-height: 50px;
    }
    > main{
      display: flex;
      justify-content: space-between;

      div{
        width: 30%;
        h4{
          margin-bottom: 40px;
          opacity: 0.6;
          text-align: center;
          font-size: 12px;
        }
        h3{
          margin-top: 10px;
          margin-bottom: 10px;
          font-size: 24px;
          line-height: 38px;
          font-weight: 800;
          text-align: left;
        }
        img{
          width: 100%;
        }
        p{
          text-align: left;
        }
      }
    }
  }
  aside{
    margin: 80px auto 40px;
    width: 90%;
  }

  aside.join, aside.sponsor{
    text-align: center;
    main{
      background-color: #f4f4f4;
      padding-top: 80px;
      padding-bottom: 50px;
      margin-bottom: 30px;
      h4{
        font-size: 30px;
        line-height: 52px;
        color: #1a1b1f;
        font-weight: 400;
        text-transform: none;
        margin-bottom: 35px;
      }
      img{
        width: 250px;
      }
      a{
        width: 170px;
        margin: 25px auto;
        display: block;
        padding: 12px 25px;
        color: #fff;
        transition: background-color 400ms ease, opacity 400ms ease, color 400ms ease;
        background-color: #1a1b1f;
      }
    }
    p{
      margin-bottom: 40px;
      opacity: 0.6;
      text-align: center;
      font-size: 12px;
    }
  }

  aside.faq{
    h2{
      margin-top: 60px;
      text-align: center;
      margin-bottom: 10px;
      font-size: 36px;
      line-height: 50px;
      font-weight: 400;
    }
    ul{
      list-style-type: disc !important;
      li{
        margin-bottom: 10px;
        color: #1a1b1f;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
      }
    }
  }

  footer{
    margin: 80px auto 40px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > img{
      display: block;
      max-width: 450px;
      width: 40%;
    }
    aside{
      max-width: 300px;
      width: 40%;
      display: block;
      p{
        margin-bottom: 10px;
      }
      a{
        display: block;
        img{
          width: 300px;
        }
      }
      img{
        width: 250px;
      }
    }
  }

  p{
    line-height: 20px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 50px;
    padding-top: 0px;
    font-size: 12px;
  }
}
</style>

