<template>
  <div id="tools-index">
    <div class="banner">
      <img src="~$images/activity/divination/picnic-banner.jpeg" />
    </div>
    <nav>
      <a href="https://liff.line.me/1653951718-MeyWzYX3">
        <font-awesome-icon :icon="['fas', 'arrow-left']" />
        返回占卜頁面
      </a>
    </nav>
    <h1>敕令占卜結果</h1>
    <main>
      <b-table :data='tools' :striped='true'>
        <b-table-column field="gettable_name" label="獲得物品" v-slot="props">
          {{ props.row.gettable_name }} * {{ props.row.quantity }}
        </b-table-column>
        <b-table-column field="token" label="" v-slot="props">
          <span v-if='props.row.is_used'>已兌換</span>
          <span v-else>
            <a @click="copyToken(props.row)">複製序號<font-awesome-icon :icon="['fas', 'copy']" /></a> |
            <a @click="shareToken(props.row)">分享序號<font-awesome-icon :icon="['fas', 'share-from-square']" /></a>
          </span>
        </b-table-column>
      </b-table>
    </main>
  </div>
</template>

<script>
import { baseRequset } from "@/api/base";
import { copyContent } from '@/components/share/copy.js';
import { lineContext } from '@/api/line.js';

export default {
  data() {
    return {
      lineId: '',
      page: 1,
      columns: [{ field: 'gettable_name', label: '獲得物品' },
                { field: 'quantity', label: '數量', numeric: true },
                { field: 'is_used', label: '是否被使用' }],
      tools: []
    };
  },
  async created() {
    const context = await lineContext('1653951718-GJYJkrDE');
    this.lineId = context.userId;
    // this.lineId = 'U016222f6eeee6bc349074ce00e46aa37';
    baseRequset.get(`/api/v4/tool_items/ticket_for_lottery/tools?line_id=${this.lineId}&page=${this.page}`)
               .then(response => {
                 response.data.tools.forEach((tool) => { this.tools.push(tool); })
               }).catch(error=> {
                 console.log(error)
               })
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        this.page += 1;
        baseRequset.get(`/api/v4/tool_items/ticket_for_lottery/tools?line_id=${this.lineId}&page=${this.page}`)
            .then(response => {
              response.data.tools.forEach((tool) => { this.tools.push(tool); })
            }).catch(error=> {
              console.log(error)
            })
      }
    },
    copyToken(tool) {
      if (tool.is_used) {
        this.$buefy.notification.open({
          message: '道具已被使用過囉～',
          type: 'is-light',
          position: 'is-bottom-right'
        });
        return;
      }
      copyContent(tool.token, '序號複製成功！快去使用吧～');
    },
    shareToken(tool) {
      console.log(tool);
      window.liff.shareTargetPicker([
        {
          type: 'text',
          text: `恭喜得到<${tool.gettable_name}>序號 * 1\n序號為：\n${tool.token}`
        }
      ])
    }
  },
};
</script>

<style lang="scss" scoped>
#tools-index {
  background: #F0E7D7;
  min-height: 100vh;
  padding-bottom: 48px;

  .banner {
    img {
      width: 100%;
    }
  }

  nav {
    a {
      padding: 8px 16px;
      color: #444;
      font-size: 14px;
      font-weight: bold;
      text-decoration: none;

      &:hover {
        background: #000;
        color: #fff;
      }

      svg {
        margin-right: 4px;
      }
    }
  }

  h1 {
    text-align: center;
    font-size: 24px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  td {
    svg {
      margin-left: 4px;
    }

    a {
      &:first-child {
        margin-right: 2px;
      }

      &:last-child {
        margin-left: 2px;
      }
    }
  }
}
</style>
