<template>
  <div id="yama" class="Yama">
    <template v-if="!isGameOver">
      <!-- banner -->
      <Banner
        :isStartGame="isStartGame"
        :hasTimeRecord="hasTimeRecord"
        :injectKey="$banner"
        @open-qa="openQuestionModal"
        @open-record="openRecordModal"
        @action-start="actionStart"
      ></Banner>

      <main class="content" :style="mainStyle">
        <component
          :is="currentStageName"
          :injectKey="$stage"
          :isEnd="isEnd"
          @choose-option="chooseAnswer"
          @finish-answer="finishAnswer"
        ></component>
      </main>
    </template>
    <StageFailEnd
      v-else
      :failedAnswer="failedAnswer"
      @open-feature="openFeatureModal"
      @renew="refreshGame"
    ></StageFailEnd>
    <StageVictoryEnd
      v-if="isEnd && !isGameOver"
      :isComponentModalActive="!isGameOver"
      :ranksInfo="ranksInfo"
    ></StageVictoryEnd>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import Banner from "./banner";
import FAQCard from "./modals/FAQCard";
import RecordCard from "./modals/RecordCard";
import FeatureCard from "./modals/featureCard";
import { useConfig } from "./composables/useConfig";
import { useGameState } from "./composables/useGameState";

export default defineComponent({
  components: {
    Banner,
    StageInit: () => import("./stageInit"),
    StageGameStart: () => import("./stageGameStart"),
    StageVictoryEnd: () => import("./stageVictoryEnd"),
    StageFailEnd: () => import("./stageFailEnd"),
  },
  props: {
    memberLineId: {
      type: String,
      required: true,
    },
    memberName: {
      type: String,
      default: "",
    },
    groupId: {
      type: [String, Number],
      required: true,
    },
  },
  channels: {
    AnswerRoomsChannel: {
      connected() {
        console.log("connected.");
        this.triggerLoading();
      },
      received(data) {
        this.receivedMessage(JSON.parse(data));
      },
      disconnected() {
        console.log("disconnected");
      },
      rejected() {
        console.log("rejected");
      },
    },
  },
  setup(props, context) {
    const {
      isEnd,
      isGameOver,
      ranksInfo,
      actionStart,
      currentStageName,
      hasTimeRecord,
      currentStage,
      isStartGame,
      receivedMessage,
      triggerLoading,
      chooseAnswer,
      finishAnswer,
      failedAnswer,
      refreshGame
    } = useGameState(props, context);
    const { mainStyle, openModal, $banner, $stage } = useConfig(context);

    return {
      isStartGame,
      hasTimeRecord,
      mainStyle,
      currentStage,
      currentStageName,
      ranksInfo,
      isEnd,
      isGameOver,
      openQuestionModal: openModal(FAQCard),
      openRecordModal: openModal(RecordCard),
      openFeatureModal: openModal(FeatureCard),
      actionStart,
      $banner,
      $stage,
      receivedMessage,
      triggerLoading,
      chooseAnswer,
      finishAnswer,
      failedAnswer,
      refreshGame
    };
  },
});
</script>

<style lang="scss">
@import "~@/assets/styles/reset";

.Yama {
  height: 100%;
  min-width: 375px;
  max-width: 480px;
  overflow: hidden;
  > .content {
    min-height: 563px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }
}
</style>
