<template>
  <div class="Base__banner">
    <img
      v-onload="{src: imgSrc, callback: bgLoaded }"
      alt="主視覺"
      draggable="false"
      class="Base__banner-bg"
      width="375px"
      height="176px"
    />
    <img
      v-onload="{src: banner_avatar, callback: avatarLoaded }"
      alt="人物"
      draggable="false"
      class="Base__banner-avatar"
      width="200px"
      height="170px"
    />
    <div class="banner-content" :style="styles">
      <p>{{ content }}</p>
    </div>
  </div>
</template>

<script>
import { defineComponent, onUpdated, ref, watch } from "@vue/composition-api";

export const bannerProps = {
  imgSrc: "",
  content: "",
  styles: "",
  banner_avatar: "",
};

export default defineComponent({
  name: "Base_Banner",
  props: {
    imgSrc: { type: String, required: true },
    content: { type: String, default: "" },
    styles: { type: String, default: "" },
    banner_avatar: { type: String, required: true },
  },
  setup(props, ctx) {
    const bgLoading = ref(false);
    const avatarLoading = ref(false);
    const bgLoaded = () => (bgLoading.value = true);
    const avatarLoaded = () => (avatarLoading.value = true);
    onUpdated(() => {
      bgLoading.value = avatarLoading.value = false;
    });

    watch([bgLoading, avatarLoading], ([a, b]) => {
      if (a && b) ctx.root.$nextTick(() => ctx.emit("onLoaded"))
    });

    return {
      bgLoaded,
      avatarLoaded,
    };
  },
});
</script>

<style lang="scss">
.Base__banner {
  position: relative;
  background-color: #000000;
  height: 176px;

  &-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-avatar {
    position: relative;
    width: 200px;
    height: 100%;
    object-fit: contain;
  }

  .banner-content {
    background-color: #fff;
    border: 1px solid #000;
    width: 144px;
    border-radius: 21px;
    position: absolute;
    top: 0;
    left: 0;
    margin-bottom: 0;

    p {
      padding: 9px 10px 9px;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.08px;
      color: #333333;
    }
  }

  img {
    opacity: 1;
    transition: opacity 0.3s;
  }

  img[data-src] {
    opacity: 0;
  }
}
</style>
