<template>
  <div>
    <ReportPayment />
  </div>
</template>

<script>
import ReportPayment from "@/components/reports/payment.vue";

export default {
  components: {
    ReportPayment
  }
};
</script>
