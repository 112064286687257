<template>
  <VCard class="FeatureCard">
    <VCardContent>
      <div class="main">
        <img :src="toolIcon" aria-hidden="true" draggable="false">
        <h3>牛奶糖</h3>
        <p>剩餘數量：<span>{{user.candy_num}}</span></p>
      </div>
      <div class="description" v-html="descriptionHTML"></div>
    </VCardContent>
    <VCardFooter>
      <button @click="$emit('close')" class="btn-default">再想想</button>
      <button @click="useToll" class="btn-primary">交給他</button>
    </VCardFooter>
  </VCard>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import VCard, {
  VCardFooter,
  VCardContent,
} from "@/components/common/VCard";
import { state, getters } from "../store";
import { YamaAPI } from "@/api";
import { useConfig } from "../composables/useConfig";

export default defineComponent({
  components: {
    VCard,
    VCardFooter,
    VCardContent,
  },
  setup (_, context) {
    const user = getters.userInfo
    const feature = '牛奶糖'
    const description1 = `<p class="description-text">Oops! <span>${feature}</span>好像用完了 🥺</p>`
    const description2 = `<p>確定把<span>${feature}</span>交給小閻王？</p>`
    const descriptionHTML = user.value.candy_num ? description2 : description1

    const { toolIcon } = useConfig(context)
    const useToll = async () => {
      try {
        const { data } = await YamaAPI.useTool({ line_id: state.lineId, group_id: state.groupId})
        const { success } = data
        if (!success) throw new Error('使用失敗...')

        alert('糖果使用成功！')
      } catch (error) {
        alert(error.message)
      }
    }

    return {
      descriptionHTML,
      user,
      useToll,
      toolIcon 
    }
  },
});
</script>

<style lang="scss">
.FeatureCard {
  text-align: center;
  overflow: hidden;

  .v-card {
    &__content {
      padding: 64px 31px 60px;
      h3 { 
        font-size: 20px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
      }

      .main {
        margin-bottom: 34px;
        > img {
          margin-bottom: 18px;
        }
      }
      

      .description {
        span {
          color: red;
        }
      }
    }
  
    &__footer {
      justify-content: center;
      padding: 0;
      border-radius: 4px;
      .btn-default,
      .btn-primary {
        width: 100%;
        padding: 24px;
        font-size: 24px;
        border: none;
      }
      .btn-default {
        background-color: #b1b1b1;
      }
      .btn-primary {
        background-color: #b6d765;
      }
    }
  }
}

</style>
