<template>
  <VCard>
    <VCardHeader>
      <h1>案件還原 FAQ</h1>
    </VCardHeader>
    <VCardContent>
      <div
        v-for="(item, index) in questionList"
        :key="index"
        class="question-item"
      >
        <p class="question-item__question">{{ item.q }}</p>
        <p class="question-item__answer">{{ item.a }}</p>
      </div>
      <div class="question-item">
        <p class="question-item__question">
          Q. 案件還原中的還原紀錄中紅點和綠點是什麼意思？
        </p>
        <p class="question-item__answer">
          A.
          紅點是<span class="text-danger">還原失敗</span>，代表所有參與的沒有任何實習判官答對這題，綠點是<span class="text-primary">還原成功</span>，代表參與的實習判官中有至少有一位答對這題。
        </p>
      </div>
    </VCardContent>
    <VCardFooter>
      <button @click="$emit('close')" class="btn-default">關閉</button>
    </VCardFooter>
  </VCard>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import VCard, {
  VCardFooter,
  VCardHeader,
  VCardContent,
} from "@/components/common/VCard";

const mockQuestionData = [
  {
    q: "Q. 如何開始還原案件？",
    a: `A.在閻王殿畫面點擊【還原案件】就會開始案件還原倒數計時，欲參與這次還原的實習判官必須在
        2 分鐘內還原所有案件細節。而還原成功或失敗會在開啟案前還原的 2
        分鐘後，或是群組內的每一位實習判官都完成案件還原後，馬上公布。`,
  },
  {
    q: "Q. 如何算案件還原成功？",
    a: `A. 大於一半參與還原（進入閻王殿答題並且按下完成）的實習判官答對全部的題目就算成功`,
  },
  {
    q: "Q. 如何看之前的還原紀錄？",
    a: `A. 在閻王殿畫面點擊【開啟還原紀錄】即可看到之前的案件還原紀錄`,
  },
];

export default defineComponent({
  components: {
    VCard,
    VCardFooter,
    VCardHeader,
    VCardContent,
  },
  props: {
    questionList: {
      type: Array,
      default: () => mockQuestionData,
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
.v-card {
  text-align: center;
  border-radius: 0;

  &__header {
    padding: 25px 0;
    justify-content: center;
    h1 {
      font-size: 24px;
      font-weight: 600;
    }
  }

  &__content {
    padding: 0 38px;
  }

  &__footer {
    justify-content: center;
    padding: 0;
    .btn-default {
      background-color: #b1b1b1;
      width: 100%;
      padding: 24px;
      font-size: 24px;
      border: none;
    }
  }
}

.question-item {
  text-align: left;
  font-size: 14px;
  color: #333333;
  margin-bottom: 16px;
  &__question {
    font-family: PingFangTC-Semibold;
  }
  &__answer {
    font-family: PingFangTC-Regular;
  }
}

.text-primary {
  color: #b6d765;
}
.text-danger {
  color: #ff0000
}
</style>
