<template>
  <div>
    <StoryShow />
  </div>
</template>

<script>
import StoryShow from "@/components/stories/show.vue";

export default {
  components: {
    StoryShow
  }
};
</script>
