<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: ["bar-data"],
  mounted() {
    this.renderChart(
      this.barData,
      {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false
        }
    );
  }
};
</script>
