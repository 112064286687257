<template>
  <div class="card">
    <div class="card-image">
      <a @click='showStoryModal(story)'>
        <img :src='story.coverImage'>
        <img :src='storyFlags[story.flag]' class="flag">
        <img v-if='isfinished' src="~$images/menu/case-closed.png" class="closed-case"/>
        <p v-if='isOwnStory'>已擁有</p>
      </a>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <a class="title" @click="showStoryModal(story)">{{story.name}}</a>
          <p class="subtitle">卷宗整理員：{{story.author.name}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['story', 'isOwnStory', 'isfinished', 'showStoryModal'],
  data() {
    return {
      star: require("@/assets/images/star.png"),
      grayStar: require("@/assets/images/gray_star.png"),
      storyFlags: {
        free_version: require("@/assets/images/menu/free.png"),
        newest_version: require("@/assets/images/menu/newest.png"),
        classic_version: require("@/assets/images/menu/classic.png"),
      },
    }
  }
}
</script>

<style scoped lang='scss'>
  .card {
    border-radius: 0;
    background: none;

    .card-image {
      position: relative;

      img {
        width: 100%;
        height: auto;
        border-radius: 0;

        &.flag {
          position: absolute;
          z-index: 100;
          max-width: 80px;
          width: 30%;
          right: 0;
        }

        &.closed-case {
          position: absolute;
          z-index: 1;
          left: 20%;
          width: 60%;
          top: 20%;
        }
      }

      p {
        position: absolute;
        bottom: 1px;
        color: red;
        opacity: 0.8;
        background: #262425;
        text-align: center;
        width: 100%;
        padding: 4px;
        font-size: 16px;
        font-weight: bold;

        @media screen and (max-width: 850px) {
          font-size: 14px;
        }

        @media screen and (max-width: 550px) {
          font-size: 12px;
        }
      }
    }

    .card-content {
      padding: 0;
      margin-top: 8px;

      .media-content {
        .title {
          display: block;
          color: #fff;
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 24px;

          @media screen and (max-width: 400px) {
            font-size: 14px;
          }
        }

        .subtitle {
          color: #fff;
          font-size: 16px;

          @media screen and (max-width: 400px) {
            font-size: 12px;
          }
        }
      }
    }
  }
</style>