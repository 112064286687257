<template>
  <ul id="related-list" v-if="listData.length">
    <li
      v-for="(related, index) in listData"
      :key="index"
      :class="[isActive(related.name) ? 'active' : '',
               isActive(related.name) && showTutorialRelated ? 'show' : '']">
      <template v-if="related.found">
        <img :src="activeIcon" alt="" v-show="isActive(related.name)" class="icon__active">
        <a @click.stop="$emit('change-related',index)">
            <img :src="icon" class="icon__main" :class="{hidden:isActive(related.name)}" />
            <p>
              {{ related.name }}
            </p>
        </a>
      </template>
      <template v-else>
        <img :src="notFoundIcon" class="no-found">
      </template>
    </li>
  </ul>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  props: {
    listData: {
      type: Array,
      required: true
    },
    activeName: {
      type: String,
      default: () => ""
    },
    icon: {
      type: String,
      default: () => require("@/assets/images/user.png")
    },
    activeIcon: {
      type: String,
      default: () => require("@/assets/images/select-related.png")
    },
    notFoundIcon: {
      type: String,
      default: () => ""
    },
    showTutorialRelated: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const isActive = (name) => name === props.activeName
    const iconActivatedStyles = computed(() => {
      return {
        "background-image": `url(${props.activeIcon})`
      }
    })

    return {
      iconActivatedStyles,
      isActive
    }
  },
})
</script>


<style lang="scss" scoped>
#related-list {
  margin: 0;
  padding: 1rem;
  width: 100%;
  list-style: none;
  white-space: nowrap;
  overflow-y: scroll;
  display: flex;

  li {
    flex-shrink: 0;
    flex-basis: 60px;
    display: inline-block;
    text-align: center;
    position: relative;
    margin: 0 5px;

    .icon__active {
      position: absolute;
      top: 0;
      left: 0;
    }

    .icon__main.hidden {
      visibility: hidden;
    }

    > .no-found {
      display: block;
      width: 60px;
      margin: 0 auto;
    }
    a {
      display: block;
      position: relative;
      color: #000000;
      > .icon__main {
        width: 60px;
      }
    }
  }
}
</style>
