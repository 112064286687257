<template>
  <div>
    <ActivityIndex />
  </div>
</template>

<script>
import ActivityIndex from "@/components/activity/index.vue";

export default {
  components: {
    ActivityIndex
  }
};
</script>