<template>
  <notifications position="bottom center">
    <template slot="body" slot-scope="{ item, close }">
      <div class="Game__notification">
        <div class="notify__photo">
          <img :src='item.data.pictureUrl'
               alt="頭像"
               draggable="false"/>
        </div>
        <slot name="content" v-bind="{ item }">
          <div class="notify__content">
            <div class="notify__content-header">
              <p class="title">
                {{ item.title }} 嘗試搜尋{{ item.data.notifyType }}
                <span class="update_list" :class="{red: !item.data.successfoundItem}">
                  {{ item.text }}
                </span>
              </p>
            </div>
            <p class="notify__content-description">{{ item.data.unlockText }}</p>
          </div>
        </slot>
        <button class="notify__btn" @click="close">x</button>
      </div>
    </template>
  </notifications>
</template>

<script>
import eventBus from "@/components/game/store/eventBus";

export default {
  data() {
    return {
      itemTypeTranlateToName: {
        exhibit: '證物',
        related: '證人',
        timeline: '時間線',
        position: '地點'
      }
    };
  },
  mounted () {
    eventBus.$on('showNotification', (data) => {
      this.showNotification(data);
    });
  },
  beforeDestroy() {
    eventBus.$off('showNotification');
  },
  methods: {
    showNotification(data) {
      this.$notify({
        title: data.member.name,
        ignoreDuplicates: true,
        max: 3,
        speed:100,
        duration:5000,
        text: data.searchText,
        data: {
          successfoundItem: data.successfoundItem,
          pictureUrl: data.member.pictureUrl,
          unlockText: data.unlockText,
          notifyType: this.itemTypeTranlateToName[data.searchType]
        }
      });
    }
  }
};
</script>

<style lang="scss">
.Game__notification {
  border-radius: 33px;
  background-color: #ffeaea;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  padding: 4px 5px;

  .notify__photo {
    width: 58px;
    height: 58px;
    border-radius: 100%;
    overflow: hidden;
    background: #ffffff;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .notify__content {
    margin-left: 8px;
    text-align: left;

    p {
      font-size: 14px;
      font-weight: 600;
      color: #333333;
    }

    .title {
      .update_list {
        margin-left: 4px;
        color: #31b120;

        &.red {
          color: #fd2e2e;
        }
      }
    }
  }

  .notify__btn {
    border: 1px solid #979797;
    border-radius: 100%;
    padding: 2px 9px 6px;
    margin-left: auto;
    margin-right: 20px;

    &:hover {
      background: #ffffff;
    }
  }
}
</style>
