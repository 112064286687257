<template>
  <section class="Detail__list" :class=[]>
    <div
      v-for="(testimony, index) in listData"
      :key="index"
      :class="[!isUnlocked(testimony)? 'block' : '',
               showAllTestimonies ? 'show' : '',
               testimony.content.includes('red') && showVictimTestimony ? 'show' : '',
               testimony.content.includes('bold') && showRelatedTestimony ? 'show' : '',
               testimony.content.includes('大學社團') && showPositionTestimony ? 'show' : '']"
    >
      <h4>{{ isUnlocked(testimony) ? testimony.title : '' }}</h4>
      <p
        v-if="isUnlocked(testimony)"
        class="detail-list"
        v-html="changeLine(testimony.content)"
      ></p>
      <img  v-if="!isUnlocked(testimony)" :src="lockIcon" aria-hidden="true" draggable="false">
    </div>
  </section>
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  props: {
    listData: {
      type: Array,
      default: () => [],
    },
    hadFoundExhibitIds: {
      type: Array
    },
    hadFoundRelatedIds: {
      type: Array
    },
    hadFoundTimelineIds: {
      type: Array
    },
    hadFoundPositionIds: {
      type: Array
    },
    lockIcon: {
      type: String,
      required: true,
    },
    showAllTestimonies: {
      type: Boolean,
      default: false
    },
    showVictimTestimony: {
      type: Boolean,
      default: false
    },
    showRelatedTestimony: {
      type: Boolean,
      default: false
    },
    showPositionTestimony: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const changeLine = (content) => {
      return content.replace(/\n/g, "<br>");
    };

    const translateItemToHadFound = {
      preExhId: 'hadFoundExhibitIds',
      preRelId: 'hadFoundRelatedIds',
      preTimeId: 'hadFoundTimelineIds',
      prePosId: 'hadFoundPositionIds'
    }

    const isUnlocked = (testomy) => {
      const isUnlocked = Object.keys(translateItemToHadFound).every(lockItem => {
        if (testomy[lockItem].length === 0) { return true; }

        return testomy[lockItem].every(lock => props[translateItemToHadFound[lockItem]].includes(lock))
      })
      return isUnlocked;
    }

    const iconStyle = computed(() => ({
      'background-image': `url(${props.lockIcon})`
    }))

    return {
      changeLine,
      isUnlocked,
      iconStyle
    };
  },
});
</script>

<style lang="scss">
.Detail__list {
  > div {
    border: solid 1px #333333;
    border-bottom: none;

    &:last-child {
      border-bottom: solid 1px #333333;
    }
    &.block {
      position: relative;
      display: block;
      height: 200px;
      color: #303030;
      background-color: #d8d8d8;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.17px;
      color: #333333;
      background-color: rgba(0, 0, 0, 0.2);
      margin-bottom: 0;
      > img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    h4 {
      font-family: PingFangTC;
      min-height:28px;
      font-size: 18px;
      font-weight: 600;
      color: #333333;
      border-bottom: solid 1px #333333;
      padding: 3px 0 4px 7px;
      margin-bottom: 0;
    }
    p {
      margin: 0;
      font-family: PingFangTC;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333333;
      padding: 7px 6px 7px 9px;
    }
  }
}
</style>
