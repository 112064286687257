<template>
  <main>
    <section v-if='selectedItems.length > 0' class="checkout">
      <h5>已選購案件</h5>
      <hr>
      <ul>
        <li v-for='(item, index) in selectedItems' :key='index'>
          <span>{{item.name}}＊{{item.price}}</span>
          <a @click='removeItem(item)'><img src="~@/assets/images/remove.png"></a>
        </li>
      </ul>
      <hr>
      <h6><strong>總金額：</strong><strong>{{selectedItems.reduce((accumulator, item) => accumulator + item.price, 0) }}</strong></h6>
      <a :href="'line://app/1653951718-gKz3KWMB?itemable_type=bundler_story&itemable_ids=' + selectedItemIds.join(',') + '&member_line_id=' + lineID"
         class="checkout">前往結帳</a>
    </section>
    <aside class="filter-condition">
      <b-checkbox v-model="onlyShowOwnStories" size="is-small" native-value="顯示未擁有案件">未擁有案件</b-checkbox>
    </aside>
    <section class="tools">
      <div v-for='(item, index) in showItems' :key='index' class="tool" :class="{seleted: isIncludedSelectedItem(item)}">
        <a @click="handleItem(item)">
          <img :src='item.image_url'>
          <p>{{ item.name }}</p>
          <p class="price">NT {{ item.price }}</p>
          <span class="seleted-content">已加入</span>
        </a>
      </div>
    </section>
  </main>
</template>
<script>
  export default {
    props: ['lineID', 'items', 'ownStoryIds'],
    data() {
      return {
        selectedItems: [],
        onlyShowOwnStories: false,
        showItems: this.items,
        selectedItemIds: []
      }
    },
    watch: {
      items: function(val) {
        this.showItems = val;
      },
      onlyShowOwnStories: function() {
        this.isShowedItems();
      }
    },
    methods: {
      handleItem(item) {
        if (this.isIncludedSelectedItem(item)) {
          this.removeItem(item)
        } else {
          this.addItem(item)
        }
      },
      isShowedItems() {
        if (this.onlyShowOwnStories) {
          this.showItems = this.items.filter((item) => !this.ownStoryIds.includes(item.itemable_id));
        } else {
          this.showItems = this.items;
        }
      },
      addItem(item) {
        this.selectedItems.push(item);
        this.selectedItemIds.push(item.itemable_id);
      },
      removeItem(item){
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
        this.selectedItemIds.splice(this.selectedItemIds.indexOf(item.itemable_id), 1);
      },
      isIncludedSelectedItem(item) {
        return this.selectedItems.some((i) => i.itemable_id === item.itemable_id);
      }
    }
  };
</script>
<style scoped lang="scss">
  section.tools {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 40vh;

    div.tool {
      background-color: #E6ECE4;
      border-radius: 8px;
      margin-top: 16px;
      box-sizing: border-box;

      a {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 98px;
        padding: 8px;
        align-items: center;
        color: #454439;
        position: relative;

        img {
          width: 100%;
          margin-bottom: 4px;
        }

        p {
          font-size: 12px;
          font-weight: 500;

          &:last-child {
            font-weight: bolder;
          }
        }

        span.seleted-content {
          position: absolute;
          color: #e5e5e5;
          font-size: 8px;
          background: red;
          top: 0;
          right: 0;
          padding: 2px 2px 3px 4px;
          display: none;
          border-radius: 0 0 0 8px;
          font-weight: 500;
        }
      }

      &.seleted {
        border: red solid 2px;

        a {
          span.seleted-content {
            display: block;
          }
        }
      }
    }
  }

  aside.filter-condition {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;

    .b-checkbox {
      .checkbox {
        .control-label {
          padding-left: 4px !important;
        }
      }
    }
  }

  section.checkout {
    background: #fff;
    border-radius: 4px;
    padding: 8px 16px;

    h5 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }

    hr {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    ul {
      li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        a {
          padding: 4px;

          img {
            align-items: center;
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    h6 {
      display: flex;
      justify-content: space-between;
    }

    a.checkout {
      display: block;
      width: 100%;
      background: #F5A623;
      border-radius: 8px;
      color: #fff;
      padding: 8px 0;
      margin-top: 16px;
      font-weight: 900;
      text-align: center;
    }
  }
</style>
