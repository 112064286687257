<template>
  <section>
    <b-modal v-model="isSelectedOptionsModalActive" :width="640" scroll="keep">
      <div class="card" :style="{ 'background-image': `url(${backgroundUrl})` }">
        <div class="card-content">
          <div class="media">
            <div class="media-left">
            </div>
            <div class="media-content">
              <p class="title is-4">{{timeline.eventTime}}</p>
            </div>
          </div>
          <div class="content">
            {{timeline.report}}
          </div>
          <div class="options">
            <a v-for="(option, index) in shuffle([timeline.answer, ...timeline.options])"
               :key="index"
               @click="$emit('selectedOption', option)">
              <div class="option"><img :src="listStyleUrl" />{{option}}</div>
            </a>
          </div>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script>
  export default {
    props: ['timeline', 'backgroundUrl', 'isCardModalActive', 'listStyleUrl'],
    data() {
      return {
        isSelectedOptionsModalActive: false
      }
    },
    watch: {
      isCardModalActive: function (e) {
        this.isSelectedOptionsModalActive = e;
      },
      isSelectedOptionsModalActive: function (e) {
        if (!e) {
          this.$emit('hideOptions');
        }
      }
    },
    methods: {
      shuffle(array) {
        let currentIndex = array.length, randomIndex;
        while (currentIndex != 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
          [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
        return array;
      },
    }
  }
</script>

<style scoped lang="scss">
  .options {
    .option {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #4a4a4a;

      img {
        width: 40px;
      }
    }
  }
</style>
