<template>
  <section class="Relateds-collapse">
    <b-collapse
      class="item"
      animation="slide"
      v-for="(collapse, index) of collapses"
      :key="index"
      :open="isOpen == index"
      @open="isOpen = index"
    >
      <template #trigger="props">
        <div
          class="item-header"
          role="button"
          :class="props.open ? 'down' : 'up'"
        >
          <img
            :src="props.open ? bookOpenIcon : bookCloseIcon"
            class="is-small"
            aria-hidden="true"
            draggable="false"
          />
          <p class="item-header__title">
            {{ collapse.title }}
          </p>
        </div>
      </template>
      <div class="item-content">
        <div class="content">
          {{ collapse.text }}
        </div>
      </div>
    </b-collapse>
    <div
      class="unCollapses item"
      v-for="(item, index) in unCollapses"
      :key="index"
    >
      <div class="item-header" role="button">
        <img
          :src="bookCloseIcon"
          class="is-small"
          aria-hidden="true"
          draggable="false"
        />
        <p class="item-header__title">
          {{ item.title }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    listData: {
      type: Array,
      required: true,
    },
    bookOpenIcon: {
      type: String,
      required: true,
    },
    bookCloseIcon: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: 0,
      collapses: [
        {
          title: "Title 1",
          text: "Text 1",
        },
        {
          title: "Title 2",
          text: "Text 2",
        },
        {
          title: "Title 3",
          text: "Text 3",
        },
      ],
      unCollapses: [{ title: "Title 3" }],
    };
  },
};
</script>

<style lang="scss">
.Relateds-collapse {
  border: 1px solid #333333;
  border-radius: 4px;
  overflow: hidden;

  .is-small {
    width: 16px;
    height: auto;
    object-fit: cover;
  }

  .item {
    border-radius: 0.25rem;
    color: #333333;
    max-width: 100%;
    position: relative;

    &-header {
      padding: 0.5rem;
      background-color: transparent;
      align-items: center;
      display: flex;

      &__title {
        font-size: 1.2rem;
        margin-left: 0.5rem;
      }
    }

    &-content {
      border-top: 1px solid #333333;
      padding: 1rem;
      font-size: 1rem;
    }
  }

  .unCollapses {
    opacity: 0.5;
  }
}
</style>
