<template>
  <form class="Search__form">
    <div>
      <input
        type="text"
        class="related-name"
        v-model="searchRelatedText"
        :placeholder="customPlaceholder"
        aria-label="Recipient's username"
        aria-describedby="button-addon2"
      />
      <button
        class="search-btn"
        @click.prevent="$emit('search-query', searchRelatedText)"
      >
        {{searchText}}
      </button>
    </div>
  </form>
</template>

<script>
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  props: {
    customPlaceholder: {
      type: String,
      default: () => "",
    },
    searchText: {
      type: String,
      default: "搜尋",
    },
    showSearchTutorial: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const searchRelatedText = ref("");

    return {
      searchRelatedText,
    };
  },
});
</script>

<style lang="scss" scoped>
.Search__form {
  padding: 0 1.5rem;
  > div {
    display: flex;
  }
  .related-name {
    flex: 2;
    height: 50px;
    font-size: 18px;
    padding-left: 10px;
    background-color: #eeeeee;
    border-radius: 3px;
    border: solid 1px #979797;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .related-name:focus {
    outline: none;
  }
  .search-btn {
    height: 50px;
    border-radius: 3px;
    width: 75px;
    background-color: #eeeeee;
    border-radius: 3px;
    border: solid 1px #979797;
    border-left: none;
    color: #333333;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
