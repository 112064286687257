<template>
  <div>
    <LandingPage />
  </div>
</template>

<script>
import LandingPage from "@/components/landing-page/index.vue";

export default {
  components: {
    LandingPage
  }
};
</script>
