const TOKEN_LIST = {
  '_': '1653951718-lowA9kVX',
  'relateds': '1653951718-QO7MJ5eD',
  'exhibits': '1653951718-l360kXNB',
  'souls': '1653951718-R7Yj13P7',
  'times': '1653951718-lowA9kVX',
  'hints': '1653951718-r2KOgZ32',
  'shop': '1653951718-e864LKdZ',
  'yama': '1653951718-5KX2eQMj'
}

const NAV_ICON_LIST = [
  { name: "yama", icon: require("@/assets/images/yama/yama-icon.png") },
  { name: "relateds", icon: require("@/assets/images/related-icon.png") },
  { name: "exhibits", icon: require("@/assets/images/exhibit-icon.png") },
  { name: "souls", icon: require("@/assets/images/soul-icon.png") },
  { name: "times", icon: require("@/assets/images/time-icon.png") },
  { name: "hints", icon: require("@/assets/images/hint-icon.png") },
];


export const getToken = type => {
  return TOKEN_LIST[type] || TOKEN_LIST._
}

export const getNavIconList = () => NAV_ICON_LIST
