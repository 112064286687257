<template>
  <section class="tools">
    <div class="tool">
      <a :href="'line://app/1653951718-gKz3KWMB?itemable_type=package&itemable_id=1&member_line_id=' + lineID">
          <img src="~$images/store/season-2.png">
        <p>1-5季案件套票</p>
        <p class="price">NT 1299</p>
        </a>
    </div>
    <div class="tool">
      <a :href="'line://app/1653951718-gKz3KWMB?itemable_type=package&itemable_id=2&member_line_id=' + lineID">
        <img src="~$images/store/season-1.png">
        <p>6-8季案件套票</p>
        <p class="price">NT 1588</p>
      </a>
    </div>
    <div class="tool">
      <a :href="'line://app/1653951718-gKz3KWMB?itemable_type=season&itemable_id=9&member_line_id=' + lineID">
        <img src="~$images/store/season-3.png">
        <p>記憶囹圄第一季</p>
        <p class="price">NT 900</p>
      </a>
    </div>
    <div v-for='(item, index) in items' :key='index' class="tool">
      <a :href="`line://app/1653951718-gKz3KWMB?itemable_type=${item.itemable_type}&quantity=${item.quantity}&itemable_id=${item.itemable_id}&$member_line_id=${lineID}`">
        <img :src='`${require(`@/assets/images${item.image_url.replace("~$images", "")}`)}`'>
        <p>{{ item.name }}</p>
        <p class="price">NT {{ item.price }}</p>
      </a>
    </div>
  </section>
</template>
<script>
  export default {
    props: ['lineID', 'items']
  };
</script>
<style scoped lang="scss">
  section.tools {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      div.tool {
        background-color: #E6ECE4;
        border-radius: 8px;
        margin-top: 16px;
        padding: 8px;

        a {
          display: flex;
          flex-direction: column;
          width: 90px;
          align-items: center;
          color: #454439;

          img {
            width: 100%;
            margin-bottom: 4px;
          }

          p {
            font-size: 12px;
            font-weight: 500;

            &:last-child {
              font-weight: bolder;
            }
          }
        }
      }
    }
</style>
