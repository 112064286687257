import { NotificationProgrammatic as Notification } from 'buefy';

export const copyContent = (content = null, successMessage = '複製成功，快去分享序號吧～') => {
  navigator.clipboard.writeText(content)
    .then(() => {
      Notification.open({
        message: successMessage,
        type: 'is-light',
        position: 'is-bottom-right'
      });
    }).catch(() => {
      let input = document.createElement('input');
      input.type = "text";
      input.setAttribute('value', content);
      document.body.appendChild(input);
      input.select();
      document.execCommand("copy");
      input.remove();
      Notification.open({
        message: successMessage,
        type: 'is-light',
        position: 'is-bottom-right'
      });
    });
}
