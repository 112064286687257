<template>
  <div>
    <Shop />
  </div>
</template>

<script>
import Shop from "@/components/shop/index.vue";

export default {
  components: {
    Shop
  }
};
</script>