<template>
  <div>
    <div v-for="(paymentAmountIncrease, key) in paymentsAmountIncrease" :key="key">
      <bar-chart v-if="paymentAmountIncrease != ''" :bar-data="paymentAmountIncrease"/>
    </div>

    <div id="season_of_payments">
      <bar-chart v-if="paymentOfSeason != ''" :bar-data="paymentOfSeason"/>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/reports/bar-chart.vue';
import axios from 'axios';


export default {
  components: {
    BarChart
  },
  mounted(){
    ['day', 'week', 'month', 'year'].forEach(period => {
      axios.get(`https://backend.judicial-intern.club/api/v1/reports/payment/increase_amount?period=${period}`)
           .then(response => {
             const data = response.data;
             this.paymentsAmountIncrease[period] = {
               labels: data.labels,
               datasets: [
                 {
                   label: data.data_name,
                   data: data.datasets,
                   pointBackgroundColor: 'white',
                   borderWidth: 1,
                   pointBorderColor: '#249EBF',
                   backgroundColor: '#f87979',
                 }
               ]
             }
           })
    })
    axios.get("https://backend.judicial-intern.club/api/v1/reports/payment/season_amount")
    .then(response => {
      this.setPaymentOfSeason(response.data)
    }).catch(error=> {
      console.log(error)
    })
  },
  data () {
    return {
      paymentsAmountIncrease: { day: '', week: '', month: '', year: '' },
      paymentOfSeason: '',    
      chartData: {
        labels: ["Babol",	"Cabanatuan",	"Daegu",	"Jerusalem",	"Fairfield",	"New York",	"Gangtok", "Buenos Aires", "Hafar Al-Batin", "Idlib"],
        datasets: [
          {
            label: 'Line Chart',
            data: [600,	1150,	342,	6050,	2522,	3241,	1259,	157,	1545, 9841],
            fill: false,
            borderColor: '#2554FF',
            backgroundColor: '#2554FF',
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [ {
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  methods: {
    setPaymentOfSeason(data){
      this.paymentOfSeason = {
        labels: data.labels,
        datasets: [
          {
            label: data.data_name,
            data: data.datasets,
						pointBackgroundColor: 'white',
						borderWidth: 1,
						pointBorderColor: '#249EBF',
            backgroundColor: '#f87979',
          }
        ]
      }
    },
    setPaymentMemberSize(data){
      this.paymentMemberSize = {
        labels: data.labels,
        datasets: [
          {
            label: data.data_name,
            data: data.datasets,
						pointBackgroundColor: 'white',
						borderWidth: 1,
						pointBorderColor: '#249EBF',
            backgroundColor: '#f87979',
          }
        ]
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
