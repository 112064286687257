import { render, staticRenderFns } from "./record.vue?vue&type=template&id=043b103b&scoped=true&"
import script from "./record.vue?vue&type=script&lang=js&"
export * from "./record.vue?vue&type=script&lang=js&"
import style0 from "./record.vue?vue&type=style&index=0&id=043b103b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "043b103b",
  null
  
)

export default component.exports