<template>
  <div>
    <ReportCake />
  </div>
</template>

<script>
import ReportCake from "@/components/reports/cake.vue";

export default {
  components: {
    ReportCake
  }
};
</script>
