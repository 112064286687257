<template>
  <div class="Game-Banner">
    <img
      :src="$banner.topicBg"
      aria-hidden="true"
      draggable="false"
      class="Game-Banner--bg"
    />
    <div class="Banner__content">
      <div class="Banner__content-character">
        <img :src="$banner.topicCharacter" alt="人物" />
      </div>
      <div class="Banner__content-actions">
        <div class="actions__header">
          <img :src="$banner.topicTitle" alt="主題" />
          <button
            v-if="hasTimeRecord"
            @click="$emit('open-record')"
            class="header-record"
          >
            <p>【開啟還原紀錄】</p>
          </button>
        </div>
        <div class="actions__footer">
          <template v-if="!isStartGame">
            <button @click="$emit('action-start')" class="footer__action-btn">
              <img :src="$banner.actionBtn.init" alt="案件還原" />
            </button>
            <button
              class="footer__question-btn"
              @click="$emit('open-qa')"
            ></button>
          </template>
          <div v-else class="reminder-timestamp">
            <div class="timestamp-title">
              <img
                :src="$banner.actionBtn.active2"
                aria-hidden="true"
                draggable="false"
                class="position-icon-2"
              />
              <img
                :src="$banner.actionBtn.active1"
                aria-hidden="true"
                draggable="false"
                class="position-icon-1"
              />
              <img
                :src="$banner.actionBtn.activeTiming"
                alt="還原倒數計時"
                draggable="false"
                class="timestamp-title--main"
              />
            </div>
            <span class="timestamp-content">{{ time }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, inject } from "@vue/composition-api";
import { useTimeTracker } from "./composables/useTimeTracker";
import { state } from "./store";

export default defineComponent({
  props: {
    isStartGame: {
      type: Boolean,
      required: true,
    },
    hasTimeRecord: {
      type: Boolean,
      required: true,
    },
    injectKey: {
      type: Symbol,
      required: true,
    },
  },
  setup(props) {
    const $banner = inject(props.injectKey);
    const time_left_by_second = computed(() => state.game.time_left_by_second);
    const { minutes, seconds } = useTimeTracker(time_left_by_second);
    const time = computed(() => `${minutes.value}:${seconds.value}`);
    return {
      $banner,
      time,
    };
  },
});
</script>

<style lang="scss">
.Game-Banner {
  position: relative;
  $padding: 18.7px;

  &--bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }

  .Banner__content {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: 20px;

    &-character {
      flex: 1;
      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      padding-right: $padding;
      padding-bottom: $padding;

      .actions {
        &__header {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          > .header-record {
            padding: 0;
            margin-top: 10px;
            font-size: 16px;
            font-weight: 600;
          }
        }

        &__footer {
          position: relative;
          display: flex;
          justify-content: center;
          .footer__action-btn {
            width: 116px;
            height: 64px;

            > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .footer__question-btn {
            position: relative;
            top: 6px;
            width: 22px;
            height: 22px;
            border-radius: 100%;
            background-color: #333333;

            &::after {
              content: "?";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              text-align: center;
              color: #bab0b0;
              font-weight: bold;
              font-size: 22px;
              top: -2px;
            }
          }
        }
      }
    }

    .reminder-timestamp {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      position: relative;
      padding-right: 18px;

      .position-icon-1,
      .position-icon-2 {
        position: absolute;
        z-index: 0;
      }

      .position-icon-1 {
        bottom: 2px;
        right: 42px;
      }

      .position-icon-2 {
        top: -6px;
        left: 4px;
      }

      .timestamp {
        &-title {
          padding-right: 10px;
          line-height: 1;
          &--main {
            position: relative;
          }
        }
        &-content {
          position: relative;
          font-size: 30px;
          font-weight: 600;
          line-height: 1;
        }
      }
    }
  }
}
</style>
