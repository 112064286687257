<template>
  <div id="stories">
    <Nav/>
    <main v-if="stories != ''">
      <div
        v-for="( story, index ) in stories"
        :key="index"
      >
        <router-link :to="'/stories/' + story.id ">

        <header>
          <img :src="story.image1">
        </header>
        <main>
          <img src="~$images/group.png" id= "marker" v-show="story.version == 'paid_version'">
          <h3> {{ story.name }} </h3>
          <p>
            <img v-for="n in story.difficulty" 
           :key="n" src="~$images/star.png" id= "stars">
          </p>
  
          <p v-html="truncate(story.report)">
          </p>
        </main>
        <footer>
          <p>本案由 <span>{{ story.author }}</span> 整理</p>
          <p id="continue">繼續閱讀</p>
          <img src="~$images/blood-transparent.png" id= "blood">
        </footer>

        </router-link> 
      </div>
    </main>
  </div>
</template> 

<script>
import Nav from "@/components/nav";
import axios from 'axios';

export default {
  components: {
    Nav
  },
  data(){
    return{
      stories: ""
    }
  },
  beforeMount(){
    axios.get("https://backend.judicial-intern.club/api/v1/stories")
    .then(response => {
      this.init(response.data)
    }).catch(error=> {
      console.log(error)
    })
  },
  methods: {
    init(data){
      this.stories = data.stories
    },
    truncate(text){
      if (text.length > 150) {
          return text.substring(0, 150) + "....";
      } else {
          return text;
      }
    }
  }
}
</script>

<style scoped lang="scss">
#stories{
  body > header {
    height: 10vh;
  }

  > main {
    max-width: 960px;
    margin: 90px auto;
    display: flex;
    flex-wrap: wrap;
    width: 90%;

    div{
      width: 283px;
      // height: 390px;
      margin: 0 8px 32px 8px;
      border-radius: 2px;
      border: solid 1px #979797;
      position: relative;

      :nth-child(n+2){
        margin-right: 0;
      }
      @media (max-width: 1024px){
        max-width: 400px;
        width: 45%;
      }

      @media (max-width: 600px){
        width: 95%;
        margin: 0 auto 32px;
      }

      header{
        img{
          width: 100%;
        }
      }
      main{
        
        padding: 5px 10px 25px;

        #marker{
           position:absolute;
           width: 100px;
           top:32%;
           left: 0%;
           z-index: -8;
        }
        #stars{
          width: 18px;
          height: 17px;
          margin-right: 2px;
          margin-bottom: 4px;
        }
         
        h3{
          color: #333333;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 0px;
        }
        p{
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #999999;
        }
      }
      footer{
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        margin-top: 40px;
        p{
          position: absolute;
          bottom: 0;
          left: 0;
          font-size: 12px;
          color: #666666;
          padding: 3px 5px;
          span{
            font-weight: 600;
          }
        }
        span.continue{
          position: absolute;
          bottom: 0;
          right: 0;
          color: #333333;
          font-size: 18px;
          padding: 3px 9px;
        }
        #continue{
          position: absolute;
          right: 0;
          bottom: 0;
            margin-bottom: 5px;
                 margin-right: 5px;
          font-size: 16px;
          text-align: right;
          color: #333333;
        }
        #blood{
          position: absolute;
          right: 0;
          bottom: 0;
        }
      }
    }
  }
}
</style>