import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api'
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowLeft, faArrowRight, faCopy, faShareFromSquare } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import App from './App.vue';
import router from './router';
import store from './store';
import Notifications from 'vue-notification';
import { plugin } from '@/components/game/shared/directive/v-onload';

Vue.use(VueCompositionAPI)
// import axios from 'axios'; 
// import './registerServiceWorker';

// function echartsInit(idname, option) {
//   const myChart = echarts.init(document.getElementById(idname));
//   myChart.setOption(option);
// }
// Vue.prototype.$echartsInit = echartsInit;
// Vue.prototype.$echarts = echarts;

// library.add(faArrowUp, faAngleLeft, faAngleRight, faExclamationCircle);
library.add(faArrowLeft, faArrowRight, faCopy, faShareFromSquare);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueAxios, axios);

Vue.use(Buefy, {
  defaultIconPack: 'fas'
});
Vue.use(Notifications)
Vue.use(plugin)

Vue.config.productionTip = false;
Vue.config.devtools = true;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
