import { ref, computed, watch, onMounted, onBeforeUnmount } from "@vue/composition-api";
import { useFetcher } from "@/composables/useFetch";
import { YamaAPI } from "@/api";
import { state, mutations } from "../store"
import { mutations as rootMutation } from "@/components/game/store";
import Vue from "vue"

export const STATUS = {
  READY: 'ready',
  ONGOING: 'ongoing',
  WAITING: 'waiting',
  PENDING: 'waiting_result'
}

const closeLIFFGAME = error => {
  if (error) alert(error)

  if (typeof window !== 'undefined') window.liff.closeWindow()
}


export function useGameState(props, context) {
  // ----------------------------------------------------------------
  // main variables
  const group_id = props.groupId
  const line_id = computed(() => props.memberLineId)
  state.lineId = line_id.value
  state.groupId = group_id
  // init variables
  let startTracker;
  let startNextTimeTracker;
  const hasConnection = ref(false)
  const hasAPIInitDone = ref(false)
  const initDone = computed(() => hasConnection.value && hasAPIInitDone.value)

  const isEnd = ref(false);
  const isGameOver = ref(false);
  const ranksIcons = {
    1: "🏅",
    2: "🥈",
    3: "🥉"
  }
  const ranksInfo = ref({
    styles: {
      image: ''
    },
    member_rank: []
  });
  const failedAnswer = ref({})
  const hasTimeRecord = computed(() => !!state.game.answer_records?.length);

  // API
  const { data, loading, error, getData } = useFetcher(YamaAPI.fetchGameInfo);

  // handle stage components
  const STAGE_STATUS = new Map([
    [STATUS.READY, "stage-init"],
    [STATUS.ONGOING, "stage-game-start"],
    [STATUS.PENDING, "stage-game-start"], // waiting -> failed game and 等待時間
  ]);
  const currentStage = computed(() => state.game.status);
  const isStartGame = computed(() => currentStage.value !== STATUS.READY)
  const currentStageName = computed(() => {
    return STAGE_STATUS.get(currentStage.value);
  });

  // ----------------------------------------------------------------
  // actions
  const triggerLoading = () => hasConnection.value = true
  const actionStart = async () => {
    await YamaAPI.fetchStartGame({ group_id })
  };

  const chooseAnswer = async ({ question_id, option }) => {
    await YamaAPI.postAnswer({ line_id: line_id.value, question_id, content: option, group_id })
  }

  const finishAnswer = async () => {
    await YamaAPI.finishAnswer({ line_id: line_id.value, group_id })
    mutations.updateGame({ status: STATUS.PENDING })
  }

  const refreshGame = async() => {
    hasAPIInitDone.value = false
    await getData({ group_id, line_id: line_id.value })
    isEnd.value = false
    isGameOver.value = false
    ranksInfo.value.member_rank.splice(0, 0)
  };

  /**
   * 接收 SOCKET 訊息更新狀態資料
   * @param {*} messageData 
   */
  const receivedMessage = async (messageData) => {
    const SOCKET_STATUS = {
      MEMBER_JOIN: 'member_join',
      START_GAME: 'start_answer',
      UPDATE_ANSWER: 'update_answer',
      FINISH_GAME: 'finished',
      RE_NEW: 'reanswer'
    }
    const status = messageData?.status
    const error = messageData?.error

    if (error) return closeLIFFGAME(messageData.message)

    // if member_join => 更新目前的在線成員
    if (status === SOCKET_STATUS.MEMBER_JOIN) return mutations.updateMember(messageData.member)
    // if start_answer => call api 更新最新資訊
    if (status === SOCKET_STATUS.START_GAME) return await getData({ group_id, line_id: line_id.value });
    // if update_answer => 更新答題資訊
    if (status === SOCKET_STATUS.UPDATE_ANSWER) return mutations.updateAnswer(messageData)
    // if finished => 遊戲結束訊息
    if (status === SOCKET_STATUS.FINISH_GAME) {
      const { success, achievement_image_url } = messageData

      if (success) {
        const member_rank = messageData.member_rank
        member_rank.sort((a, b) => a.rank - b.rank).reduce((acc, member) => {
          const memberInfo = state.game.members.find(m => m.line_id === member.line_id)

          const data = {
            ...member,
            ...memberInfo,
            icon: ranksIcons[member.rank]
          }

          acc.push(data)
          return acc
        }, ranksInfo.value.member_rank)
        ranksInfo.value.styles.image = achievement_image_url
      } else {
        const { next_solve_time_by_second, answer } = messageData
        Vue.set(state.game, 'next_solve_time_by_second', next_solve_time_by_second)
        answer.my_answer = answer.member_answers.find(
          (r) => r.line_id === state.lineId)?.answers || [];

        failedAnswer.value = answer
        if (!startNextTimeTracker) startNextTimeTracker = mutations.startNextTimeTracker()
      }


      isEnd.value = true
      isGameOver.value = !success

      return
    }
    // if reanswer => 遊戲重新開始
    if (status === SOCKET_STATUS.RE_NEW) return await refreshGame()
  }

  /**
   * 偵測初始化是否完畢
   * 完畢就關閉loading
   */
  watch(initDone, (status) => {
    if (!status) rootMutation.openLoading()
    else rootMutation.closeLoading()
  }, {
    flush: 'post'
  })

  /**
   * 偵測主要 API 回傳的狀態
   * 1. 等待 api response -> data or error and loading false  (closeLoading)
   * 2. 依照每次 data status 來判斷目前遊戲狀態
   */
  watch([data, error, loading], ([data, error, loading]) => {
    if (loading) return
    // error => alert & close
    if (error) return closeLIFFGAME("請重新加入遊戲！謝謝")

    if (data) {
      if (data.error && data.message) return closeLIFFGAME(data.message)

      hasAPIInitDone.value = true
      // setting game status
      mutations.updateGame(data)
      if (!startTracker) startTracker = mutations.startTimeTracker()

      // 遊戲失敗結束等待中
      isEnd.value = false
      isGameOver.value = state.game.status === STATUS.WAITING
      if (isGameOver.value) {
        failedAnswer.value = mutations.getFailedAnswer()
        if (!startNextTimeTracker) startNextTimeTracker = mutations.startNextTimeTracker()
      }
    }
  }, {
    flush: 'post'
  })


  onMounted(async () => {
    await getData({
      group_id,
      line_id: line_id.value,
    });
    context.root.$cable.subscribe({
      channel: "AnswerRoomsChannel",
      group: `${group_id}`
    });
  });

  onBeforeUnmount(() => {
    context.root.$cable.unsubscribe("AnswerRoomsChannel");
    Vue.set(state, 'game', {})
    clearInterval(startTracker)
    clearInterval(startNextTimeTracker)
  })

  return {
    isEnd,
    isGameOver,
    ranksInfo,
    actionStart,
    currentStageName,
    hasTimeRecord,
    isStartGame,
    currentStage,
    receivedMessage,
    triggerLoading,
    chooseAnswer,
    finishAnswer,
    failedAnswer,
    refreshGame
  }
}