// import Vue from "vue";
import { reactive, computed } from "@vue/composition-api";

export const state = reactive({
  lineId: '',
  groupId: '',
  game: {}
})

export const getters = {
  customAnswer: computed(() => {
    const id = state.lineId;
    return state.game.answer_records.map((record) => {
      record.my_answer = record.member_answers.find(
        (r) => r.line_id === id
      )?.answers || [];
      return record;
    });
  }),
  onlineUserList: computed(() => {
    return state.game.members.sort((a, b) => +b.online - +a.online)
  }),
  currentOptions: computed(() => {
    return state.game.questions[0]
  }),
  userInfo: computed(() => {
    return state.game.members.find(m => m.line_id === state.lineId)
  }),
  userOngoingAnswer: computed(() => {
    return state.game.ongoing_answers.find(raw => raw.line_id === state.lineId)
  })
}

export const mutations = {
  updateGame(payload) {
    state.game = { ...state.game, ...payload }
  },
  updateMember(newMember) {
    const index = state.game.members.findIndex(member => member.line_id === newMember.line_id)

    if (index >= 0) {
      state.game.members[index].online = true
      return
    }

    state.game.members.push(newMember)
  },
  startTimeTracker() {
    if (!state.game.time_left_by_second) return null

    const oneSecondMillis = 1000
    const startTracker = setInterval(() => {
      if (state.game.time_left_by_second === 0) return clearInterval(startTracker)

      state.game.time_left_by_second -= 1
    }, oneSecondMillis);

    return startTracker
  },
  startNextTimeTracker() {
    if (!state.game.next_solve_time_by_second) return null

    const oneSecondMillis = 1000
    const startTracker = setInterval(() => {
      if (state.game.next_solve_time_by_second === 0) return clearInterval(startTracker)

      state.game.next_solve_time_by_second -= 1
    }, oneSecondMillis);

    return startTracker
  },
  updateAnswer(messageData) {
    const { time_left_by_second, member_id, question_id, answer } = messageData

    state.game.time_left_by_second = time_left_by_second
    const index = state.game.ongoing_answers.findIndex(a => a.line_id === member_id)
    const q_index = state.game.ongoing_answers[index].answers.findIndex(q => q.question_id === String(question_id))
    if (q_index < 0) {
      state.game.ongoing_answers[index].answers.push({ question_id, answer })
      return
    }

    state.game.ongoing_answers[index].answers[q_index].answer = answer
  },
  getFailedAnswer () {
    if (!state.game.answer_records) return {}

    const answer = state.game.answer_records[state.game.answer_records.length - 1];
    answer.my_answer = answer.member_answers.find(
      (r) => r.line_id === state.lineId)?.answers || [];

    return answer
  }
}
