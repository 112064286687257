<template>
  <div>
    <ReportGroup />
  </div>
</template>

<script>
import ReportGroup from "@/components/reports/group.vue";

export default {
  components: {
    ReportGroup
  }
};
</script>
