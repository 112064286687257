<template>
  <div>
    <ReportStory />
  </div>
</template>

<script>
import ReportStory from "@/components/reports/story.vue";

export default {
  components: {
    ReportStory
  }
};
</script>
