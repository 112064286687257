import Vue from "vue";
import VueRouter from "vue-router";
import LandingPage from "../views/landing-page.vue";
import Game from "../views/game.vue";
import Shop from "../views/shop.vue";
import Profile2 from "../views/profile-2.vue";
import CaseStorage from "../views/case-storage.vue";
import Reports from "../views/reports.vue";
import ReportCake from "../views/report-cake.vue";
import Moritarot from "../views/moritarot.vue";
import ReportStory from "../views/report-story.vue";
import ReportPayment from "../views/report-payment.vue";
import ReportMember from "../views/report-member.vue";
import ReportGroup from "../views/report-group.vue";
import Stories from "../views/stories.vue";
import Tools from "../views/tools.vue";
import StoryShow from "../views/story-show.vue";
import Activity from '../views/activity.vue';
import ActivityScratchCard from '../views/activity/scratch-card.vue'
import Menu from "../views/menu.vue";
// import { ReportBase } from "istanbul-lib-report";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage
  }, {
    path: "/stories",
    name: "Stories",
    component: Stories
  }, {
    path: "/tools",
    name: "Tools",
    component: Tools
  }, {
    path: "/stories/:id",
    name: "StoryShow",
    component: StoryShow
  }, {
    path: "/game/:judge",
    name: "Game",
    component: Game
  }, {
    path: "/profile2",
    name: "Profile2",
    component: Profile2
  }, {
    path: "/case-storage",
    name: "CaseStorage",
    component: CaseStorage
  }, {
    path: "/shop/:page",
    name: "Shop",
    component: Shop
  }, {
    path: "/activity",
    name: "Activity",
    component: Activity,
    children: [
      { path: 'scratch-card', name: 'ActivityScratchCard', component: ActivityScratchCard }
    ]
  }, {
    path: '/menu/:page',
    name: 'Menu',
    component: Menu
  }, {
    path: "/reports",
    name: "Reports",
    component: Reports,
    children: [
      {
        path: "cake",
        name: "ReportCake",
        component: ReportCake
      },
      {
        path: "story",
        name: "ReportStory",
        component: ReportStory
      },
      {
        path: "payment",
        name: "ReportPayment",
        component: ReportPayment
      },
      {
        path: "member",
        name: "ReportMember",
        component: ReportMember
      },
      {
        path: "group",
        name: "ReportGroup",
        component: ReportGroup
      },
      {
        path: "moritarot",
        name: "Moritarot",
        component: Moritarot
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  // base: "/test-deploy-vue",
  routes,
  linkActiveClass: "active",
  props: true
});

export default router;
