<template>
  <aside class="ads">
    <a :href="ad.href + memberId">
      購買<span>{{ ad.title }}</span>{{ ad.subTitle }}
    </a>
    <img src="~$images/cat_icon.png" alt="cat_icon" class="cat-icon" />
  </aside>
</template>

<script>
  export default {
    props: ['memberId'],
    data() {
      return {
        ad: [{ href: 'line://app/1653951718-gKz3KWMB?itemable_type=package&itemable_id=1&member_line_id=',
               title: '1 ~ 5季', subTitle: '全案件套票' },
             { href: 'line://app/1653951718-gKz3KWMB?itemable_type=season&itemable_id=9&member_line_id=',
               title: '記憶囹圄', subTitle: '首部曲!' },
             { href: 'line://app/1653951718-gKz3KWMB?itemable_type=season&itemable_id=14&member_line_id=',
               title: '第九季', subTitle: '全新推出' }][Math.floor(Math.random() * 3)]
      }
    }
  }
</script>
<style scoped lang="scss">
  .ads {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #790101;
    border: 3px solid #a90000;
    width: 360px;
    margin: 24px auto;
    border-radius: 16px;
    padding: 8px 16px;

    a {
      color: #fff;
      font-size: 22px;
      font-weight: 500;
      margin-right: 16px;

      span {
        color: #ffc107;
        font-weight: 900;
        padding: 0 4px;
      }
    }
  }
</style>