<template>
  <section>
    <b-modal v-model="showStoryModal" :width="640" scroll="keep">
      <div class="card">
        <header>
          <h4>{{story.name}}</h4>
          <p>卷宗整理員： <strong>{{authorName(story.author)}}</strong></p>
          <Level :level="story.difficulty" />
        </header>
        <div class="card-image">
          <img v-if="story.reportImage" :src="story.reportImage" alt="Image">
          <p v-else>{{story.report}}</p>
        </div>
        <div class="card-content">
          <div class="content">
            <a v-if="isAvailableBuy" @click='exchangeStory' class="btn btn-change" type="button">鎮魂璧兌換</a>
            <a @click="startStory" class="btn btn-play" type="button">{{isAvailablePlay ?  '開始遊戲' : '試玩案件'}}</a>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- FIXME: 將 modal 做成 component -->
    <b-modal v-model="showStartStoryWarning"
             has-modal-card
             trap-focus
             :destroy-on-hide="false"
             aria-role="dialog"
             aria-label="Example Modal"
             close-button-aria-label="Close"
             aria-modal>
      <section class="start-story-warning-popup">
        <div class="card">
          <p>已開啟案件！！若案件未成功開啟，起將下列文字複製至群組中</p>
          <a class="btn" @click="copyContent">我要開啟「{{story.name}}」(點我複製)</a>
        </div>
      </section>
    </b-modal>
    <b-modal v-model="showChangeStoryPopup"
             has-modal-card
             trap-focus
             :destroy-on-hide="false"
             aria-role="dialog"
             aria-label="Example Modal"
             close-button-aria-label="Close"
             aria-modal>
      <section class="change-story-popup">
        <div class="card">
          <p>目前擁有 <strong>{{profile.point}}</strong> 枚鎮魂璧，確定要花 <strong>{{story.point}}</strong> 枚鎮魂璧解鎖案件嗎？</p>
          <a class="btn" @click="unlockStory">我要解鎖「<strong>{{story.name}}</strong>」</a>
        </div>
      </section>
    </b-modal>
    <b-modal v-model="showChangeStoryInfo"
             has-modal-card
             trap-focus
             :destroy-on-hide="false"
             aria-role="dialog"
             aria-label="Example Modal"
             close-button-aria-label="Close"
             aria-modal>
      <section class="start-story-warning-popup">
        <div class="card">
          <p>{{changeStoryInfo}}</p>
        </div>
      </section>
    </b-modal>
  </section>
</template>

<script>
  import Level from './levels.vue';
  import axios from 'axios';

  export default {
    props: ['isStoryModalActive', 'story', 'isAvailablePlay', 'isAvailableBuy', 'profile', 'updateOwnStoryId'],
    components: { Level },
    data() {
      return {
        showStoryModal: false,
        showStartStoryWarning: false,
        showChangeStoryPopup: false,
        showChangeStoryInfo: false,
        changeStoryInfo: '',
        storyFlags: {
          free_version: require("@/assets/images/menu/free.png"),
          newest_version: require("@/assets/images/menu/newest.png"),
          classic_version: require("@/assets/images/menu/classic.png"),
        },
      }
    },
    watch: {
      isStoryModalActive: function(e) {
        if (e) { this.showStoryModal = e; }
      },
      showStoryModal: function(e) {
        if (!e) { this.$emit("closeStoryModalActive", false); }
      }
    },
    methods: {
      authorName (author) {
        return author ? author.name : '無';
      },
      startStory() {
        axios.post(`https://backend.judicial-intern.club/api/v2/groups/${this.profile.groupId || this.profile.userId}/group_story_ships?storyId=${this.story.id}&memberId=${this.profile.userId}`)
             .then(async (response) => {
               if (response.status === 200) {
                 try {
                   await window.liff.sendMessages([{ type: 'text', text: `我要開啟「${this.story.name}」` }])
                 } catch(e) { console.log(e) }
                 this.showStartStoryWarning = true;
               }
             })
             .catch(err => console.log(err));
      },
      exchangeStory() {
        this.showChangeStoryPopup = true;
      },
      unlockStory() {
        axios.post(`https://backend.judicial-intern.club/api/v4/transactions?story_id=${this.story.id}&line_id=${this.profile.userId}`)
             .then(response => {
              const data = response.data;
              this.changeStoryInfo = data.message || data.error;
              this.showChangeStoryPopup = false;
              this.showChangeStoryInfo = true;
              if (data.message) { this.updateOwnStoryId(this.story.id); }
            }).catch(err => alert(err))
      },
      copyContent() {
        const content = `我要開啟「${this.story.name}」`;
        navigator.clipboard.writeText(content)
                 .then(() => {
                   this.$buefy.notification.open({
                     message: '複製成功，快去開啟案件吧～',
                     type: 'is-light',
                     position: 'is-bottom-right'
                   });
                 })
                 .catch(() => {
                   let input = document.createElement('input');
                   input.type = "text";
                   input.setAttribute('value', content);
                   document.body.appendChild(input);
                   input.select();
                   document.execCommand("copy");
                   input.remove();
                   this.$buefy.notification.open({
                     message: '複製成功，快去開啟案件吧～',
                     type: 'is-light',
                     position: 'is-bottom-right'
                   });
                 })
      }
    }
  }
</script>

<style scoped lang='scss'>
  section {
    .card {
      padding: 16px 16px;
      border-radius: 8px;

      > p {
        font-size: 14px;
      }

      > a {
        font-size: 12px;
      }

      header {
        margin-bottom: 8px;

        h4 {
          font-size: 28px;
          font-weight: bold;
        }

        > p {
          margin-bottom: 8px;
        }
      }

      .card-image {
        p {
          margin-top: 16px;
          margin-bottom: 16px;
          font-size: 20px;
        }
      }

      .card-content {
        padding: 8px 0;

        .content {
          a {
            text-align: center;
            min-width: 95%;
            border-radius: 25px;
            border: none;
            color: white;
            font-weight: bold;
            letter-spacing: 1.5px;
            box-shadow: -3px 3.8px 6px 0.6px rgba(0, 0, 0, 0.3);
            display: block;
            font-size: 14px;
            padding: 8px;
            margin-bottom: 16px;
            
            &.btn-change {
              background-color: #7CAF5C;
            }

            &.btn-play {
              background-color: #4d4d4d;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .change-story-popup {
      .card {
        padding: 16px 24px;
        border-radius: 8px;

        > p {
          font-size: 16px;
        }

        > a {
          font-size: 12px;
        }

        .btn {
          text-align: center;
          min-width: 95%;
          border-radius: 16px;
          border: none;
          color: white;
          font-weight: bold;
          letter-spacing: 1.5px;
          box-shadow: -3px 3.8px 6px 0.6px rgba(0, 0, 0, 0.3);
          background-color: #7CAF5C;
          display: block;
          font-size: 14px;
          padding: 8px;
          margin-top: 16px;
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
</style>