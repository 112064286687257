import axios from "axios";

const $axios = axios.create({
  baseURL: 'https://backend.judicial-intern.club'
})

export const YamaAPI = {
  fetchGameInfo ({group_id = '', line_id = ''}) {
    const params = { group_id , line_id }
    return $axios.get('/api/v2/answers', { params })
  },
  fetchStartGame ({group_id = ''}) {
    const params = { group_id }
    return $axios.post('/api/v2/answers', null, { params })
  },
  postAnswer ({line_id, question_id, content, group_id }) {
    const params = { question_id, content, group_id }
    return $axios.put(`/api/v2/member_answers/${line_id}`, null, { params })
  },
  finishAnswer ({line_id, group_id}) {
    const params = { finish: true, group_id }
    return $axios.put(`/api/v2/member_answers/${line_id}`, null, { params })
  },
  useTool ({line_id, group_id}) {
    const params = { group_id }
    return $axios.get(`/api/v2/tool/${line_id}`, { params })
  }
}



export default {
  setProfile ({payload}) {
    return $axios.post("/profile", payload)
  },
  getmemberInfo (lineID) {
    return $axios.get(`/api/v2/member_infos/${lineID}`)
  },
  // getLineProfileByYama (payload) {
  //   return $axios.get("/api/v1/line/relateds", payload)
  // },
  /**
   * 主題 API
   * @param {*} line_id 
   * @returns 主題資訊
   */
  getTopicInfo (line_id) {
    return axios.get(`https://backend.judicial-intern.club/api/v2/members/${line_id}`)
  }
}
