import Vue from "vue";
import Vuex from "vuex";
// import auth from "./modules/auth";

Vue.use(Vuex);
export default new Vuex.Store({
  // getters,
  state: { token: localStorage.getItem('user-token') || '' },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    auth_success(state, token, user){
      state.status = 'success'
      state.token = token
      state.user = user
    },
    auth_error(state){
      state.status = 'error'
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
  },
  actions: {},
  modules: {
    // auth
  }
});
