<template>
  <div class="current-record">
    <div class="main__content">
      <h4>{{order}}</h4>
      <div class="users">
        <div class="user" v-for="member in record.member_answers" :key="member.line_id">
          <img :src="member.image_url" alt="頭像" draggable="false" width="20px" height="20px" />
        </div>
      </div>
      <div class="records">
        <span v-for="(e, num) in record.error_num" :key="num">X</span>
      </div>
    </div>
    <div class="stage__record">
      <div class="record-item">
        <p class="record-item--title">你的還原</p>
      </div>
      <div class="record-item bg-record">
        <p class="record-item--title">隊伍還原</p>
      </div>
      <template v-for="(ans, index) in record.group_answers">
        <div class="record-item" :key="index">
          <p>{{record.my_answer[index]}}</p>
        </div>
        <div class="record-item bg-record" :key="'group' + index">
          <p>{{ans}}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const order = computed(() => `第 ${props.record.order} 次還原`)

    return {
      order
    }
  },
})
</script>


<style lang="scss" scoped>
.current-record {
  background-color: #ffdfd7;
  display: flex;
  flex-direction: column;
  max-width: 229px;
  margin: 0 auto;

  p {
    color: #333333;
  }
  .main__content {
    flex: 1;
    min-height: 104px;
    padding: 15px;
    h4 {
      font-size: 16px;
      font-weight: 600;
    }
    .users {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 6px 0;

      .user {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        overflow: hidden;
        background-color: #ffffff;
      }
    }
  }

  .stage__record {
    display: flex;
    flex-wrap: wrap;

    .record-item {
      width: 50%;
      padding: 5px 10px;

      p {
        font-size: 14px;
        line-height: normal;
        margin: 0;
        &.record-item--title {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
    }

    .bg-record {
      background-color: #b6d765;
    }
  }

  .records {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    color: red;
    font-weight: 600;
    font-size: 16px;
  }
}
</style>
