<template>
  <div class="nav">
    <nav>
      <a
        v-for="item in navList"
        :key="item.name"
        @click="$emit('change-judge', item.name)"
        v-bind:class="{ active: judge === item.name }"
      >
        <img :src="item.icon" />
      </a>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
.nav {
  background: black;
  nav {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: scroll;
    padding: 10px 20px;

    a {
      flex-shrink: 0;
      width: 40px;
      height: 40px;
      margin-right: 20px;
      background-color: #ffffff;
      border-radius: 50%;
      overflow: hidden;
      opacity: 0.5;
      &.active {
        opacity: 1;
      }
      img {
        width: 100%;
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    judge: {
      type: String,
      required: true,
    },
    navList: {
      type: Array,
      required: true,
    },
  },
};
</script>
