<template>
  <div>
    <ScratchCard />
  </div>
</template>

<script>
import ScratchCard from "@/components/activity/scratch-card.vue";

export default {
  components: {
    ScratchCard
  }
};
</script>
