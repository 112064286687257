export const lineContext = async (liffId) => {
  try {
    await window.liff.init({ liffId: liffId })
    if (!window.liff.isLoggedIn()) {
      window.liff.login()
    }
    const context = await window.liff.getContext();
    return context;
  } catch (err) {
    alert(err);
  }
};
